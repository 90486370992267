import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "styled-components";
import Context from "../../../../contexts/AppContext";
import macbook from "../../images/productlist/macbook.jpeg";
import fridge from "../../images/productlist/fridge.jpeg";
import FinBox from "../../images/finboxDark.svg";
import OTPPopOver from "../../OTPPopOver";

import styled from "styled-components";
import {
  Head,
  Wrapper,
  Content,
  SubText,
  Card,
  Chip,
  SmallText,
  StatusHead,
  Row,
  HL,
  Button,
} from "../../Common";

// Dashboard also has a loading state

const Key = styled(SubText)`
  width: 50%;
  font-size: 12px;
`;
const Value = styled(SubText)`
  padding-left: 5px;
  font-size: 12px;
`;

const PaymentCard = ({
  selected,
  head,
  amount,
  soon,
}: {
  selected?: boolean;
  head: any;
  amount: string;
  soon?: boolean;
}) => {
  return (
    <Card
      style={{
        border: `1px solid ${
          selected ? "#24ca7a" : soon ? "#ececec" : "#cecece"
        }`,
        flexDirection: "column",
        marginTop: "10px",
      }}
    >
      <Row
        style={{
          boxSizing: "border-box",
          padding: "13px 13px 0px",
          width: "100%",
        }}
      >
        <Key
          style={{ fontWeight: 500, color: `${soon ? "#777777" : "#3e3e3e"}` }}
        >
          {head}
        </Key>
        <Value
          style={{ fontWeight: 800, color: `${soon ? "#777777" : "#3e3e3e"}` }}
        >
          {amount}
        </Value>
      </Row>
      <Row
        style={{
          boxSizing: "border-box",
          padding: "4px 13px 13px",
          width: "100%",
        }}
      >
        <Key
          style={{
            fontSize: "8px",
            lineHeight: "10px",
            color: `${soon ? "#c4c4c4" : "#777777"}`,
          }}
        >
          * Payment date subject to actual disbursal.
        </Key>
        <Value
          style={{
            fontSize: "8px",
            lineHeight: "10px",
            color: `${soon ? "#c4c4c4" : "#777777"}`,
          }}
        >
          0% interest
          <br />
          No Charges
        </Value>
      </Row>
    </Card>
  );
};

const CreditLineSelectMode = ({
  changeStory,
}: {
  changeStory: (hash: string) => any;
}) => {
  const theme = useContext(ThemeContext);
  const context = Context();

  
  return (
    <Wrapper>
      <Content>
        <Head>Pay ₹ 55,000</Head>
        <div style={{ display: "flex", alignSelf: "flex-start" }}>
          <SmallText
            style={{
              fontSize: "8px",
              marginBottom: "-3px",
              marginRight: "4px",
            }}
          >
            Powered by
          </SmallText>
          <img
            style={{
              height: "16px",
            }}
            src={FinBox}
          />
        </div>
        <br />

        <PaymentCard selected head="Pay in 10 Days" amount="₹ 55,000" />
        <PaymentCard head="Pay in 30 Days" amount="₹ 55,000" />
        <PaymentCard
          head={
            <>
              Pay in Installments <br />
              <span style={{ color: "#b1b1b1" }}> ( Coming Soon )</span>
            </>
          }
          amount="₹ 55,000"
          soon
        />

        <div style={{ position: "absolute", bottom: "10px", width: "100%" }}>
          <OTPPopOver changeStory={changeStory} to="Done" />
        </div>
      </Content>
    </Wrapper>
  );
};

export default CreditLineSelectMode;
