import React, { useContext, useState, useEffect } from "react";
import { ThemeContext } from "styled-components";
import Context from "../../../contexts/AppContext";

import Timeline from "../Timeline";
import {
  Wrapper,
  Content,
  Card,
  StatusHead,
  SubText,
  Button,
  SecHead,
  SmallText,
} from "../Common";

const Dashboard = ({
  changeStory,
  stage,
}: {
  changeStory: (hash: string) => any;
  stage: string;
}) => {
  const theme = useContext(ThemeContext);
  const context = Context();

  const [timeline, setTimeline] = useState<any[]>([
    {
      module: "ELIGIBLE",
      head: (
        <>
          Eligible
          {context.loanType === "CREDITLINE" ? " Credit Line" : "Loan Amount"}
          <br />
          <SecHead>₹ {context.eligibleAmount}</SecHead>
        </>
      ),
      sub: (
        <>
          *subjected to document approval
          <br />
        </>
      ),
      status: "done",
      big: true,
    },
    {
      head: "Complete Your KYC",
      module: "KYC",
      status: "pending",
      sub: "Upload Aadhaar, PAN card & verify with Selfie",
      big: false,
    },
    {
      head: context.loanType === "NORMAL" ? "Verify Bank" : "Setup Enach",
      module: "PENNYDROP",
      status: "pending",
      sub:
        context.loanType === "NORMAL"
          ? "Enter your bank details for crediting the money"
          : "Enter your bank details for ENACH",
      big: false,
    },
    {
      head: "Sign Agreement",
      module: "SIGN",
      status: "pending",
      sub: "Sign loan agreement on app using OTP.",
      big: false,
    },
  ]);

  useEffect(() => {
    let temp_timeline = [...timeline];
    for (let a of temp_timeline) {
      a.status = "done";
      if (a.module === stage) {
        a.status = "next";
        break;
      }
    }
    setTimeline(temp_timeline);
  }, [stage]);

  const ButtonText: any = {
    KYC: "Complete KYC",
    SIGN: "Sign Agreement",
    PENNYDROP: context.loanType === "NORMAL" ? "Verify Bank" : "Setup Enach",
  };

  const StatusText: any = {
    KYC: "Please upload your KYC documents",
    SIGN: "Please Sign Loan Agreement",
    PENNYDROP: "Please complete filling Bank Account Details",
  };

  const onSubmit = () => {
    switch (stage) {
      case "KYC":
        changeStory("KYC");
        break;
      case "PENNYDROP":
        changeStory("PennyDrop");
        break;
      case "SIGN":
        changeStory("Esign");
        break;
      default:
        break;
    }
  };

  return (
    <Wrapper>
      <Content>
        <>
          <StatusHead
            style={{
              color: theme.secondaryTextColor,
              alignSelf: "flex-start",
            }}
          >
            <div
              style={{ color: theme.secondaryTextColor }}
            >{ context.loanType==="CREDITLINE"?"Just a few more steps left": "Congratulations, Ramesh !"}</div>
          </StatusHead>

          <SubText style={{ marginTop: "10px", alignSelf: "flex-start" }}>
            {StatusText[stage]}
          </SubText>
          <Card
            style={{
              flexDirection: "column",
              padding: "20px 0",
              marginTop: "20px",
            }}
          >
            <div style={{ width: "85%" }}>
              <Timeline timeline={timeline} style={{ paddingTop: "20px" }} />

              <Button
                style={{ width: "100%", marginTop: "35px" }}
                onClick={onSubmit}
              >
                {ButtonText[stage]}
              </Button>
            </div>
          </Card>
        </>
      </Content>
    </Wrapper>
  );
};

export default Dashboard;
