export default {
  GOOGLE: {
    companyURL: "//logo.clearbit.com/google.in",
    primaryTextColor: "#3E3E3E",
    secondaryTextColor: "#0F9D58",
    secondaryTextBackground: "#d8f9ee",
    primaryButtonBackground: "#4285F4",
    primaryButtonTextColor: "#ffffff",
    secondaryButtonBackground: "#ffffff",
    secondaryButtonTextColor: "#3E3E3E",
    secondaryButtonBorderColor: "#4285F4",
    buttonDisabledBackground: "#d4d4d4",
    buttonDisabledTextColor: "#ffffff",
    cardBackground: "#F4F8FC",
    warningCardBackground: "#FFF2DC",
    tileBackground: "#F3F3F3",
    errorColor: "#DB4437",
    focusColor: "#4285F4",
    focusBackground: "#d8f9ee",
  },
  STOREKING: {
    companyURL: "//logo.clearbit.com/storeking.in",
    primaryTextColor: "#3E3E3E",
    secondaryTextColor: "#EE6212",
    secondaryTextBackground: "#EE621220",
    primaryButtonBackground: "#EE6212",
    primaryButtonTextColor: "#ffffff",
    secondaryButtonBackground: "#ffffff",
    secondaryButtonTextColor: "#3E3E3E",
    secondaryButtonBorderColor: "#EE6212",
    buttonDisabledBackground: "#d4d4d4",
    buttonDisabledTextColor: "#ffffff",
    cardBackground: "#F4F8FC",
    warningCardBackground: "#FFF2DC",
    tileBackground: "#F3F3F3",
    errorColor: "#DB4437",
    focusColor: "#EE6212",
    focusBackground: "#EE621220",
  },
  SNAPBIZZ: {
    companyURL: "https://snapbizz.com/wp-content/uploads/2018/09/logo.png",
    primaryTextColor: "#3E3E3E",
    secondaryTextColor: "#24CA7A",
    secondaryTextBackground: "#d8f9ee",
    primaryButtonBackground: "#24CA7A",
    primaryButtonTextColor: "#ffffff",
    secondaryButtonBackground: "#ffffff",
    secondaryButtonTextColor: "#3E3E3E",
    secondaryButtonBorderColor: "#24CA7A",
    buttonDisabledBackground: "#d4d4d4",
    buttonDisabledTextColor: "#ffffff",
    cardBackground: "#F4F8FC",
    warningCardBackground: "#FFF2DC",
    tileBackground: "#F3F3F3",
    errorColor: "#ff6d55",
    focusColor: "#24CA7A",
    focusBackground: "#d8f9ee",
  },
  ARZOOO: {
    companyURL: "//logo.clearbit.com/arzooo.com",
    primaryTextColor: "#000000d9",
    secondaryTextColor: "#1890ff",
    secondaryTextBackground: "#d8f9ee",
    primaryButtonBackground: "#1890ff",
    primaryButtonTextColor: "#ffffff",
    secondaryButtonBackground: "#ffffff",
    secondaryButtonTextColor: "#000000d9",
    secondaryButtonBorderColor: "#1890ff",
    buttonDisabledBackground: "#d4d4d4",
    buttonDisabledTextColor: "#ffffff",
    cardBackground: "#F4F8FC",
    warningCardBackground: "#FFF2DC",
    tileBackground: "#F3F3F3",
    errorColor: "#ff6d55",
    focusColor: "#1890ff",
    focusBackground: "#1890ff20",
  },
  OXIGEN: {
    companyURL: "https://www.oxigenwallet.com/public/images/logo-mobile.png",
    primaryTextColor: "#494a4a",
    secondaryTextColor: "#1890ff",
    secondaryTextBackground: "#d8f9ee",
    primaryButtonBackground: "#085eb8",
    primaryButtonTextColor: "#ffffff",
    secondaryButtonBackground: "#ffffff",
    secondaryButtonTextColor: "#000000d9",
    secondaryButtonBorderColor: "#1890ff",
    buttonDisabledBackground: "#d4d4d4",
    buttonDisabledTextColor: "#ffffff",
    cardBackground: "#F4F8FC",
    warningCardBackground: "#FFF2DC",
    tileBackground: "#F3F3F3",
    errorColor: "#ff6d55",
    focusColor: "#085eb8",
    focusBackground: "#085eb820",
  },
  NIYO: {
    companyURL:
      "https://www.goniyo.com/wp-content/uploads/2020/01/2-ni-yo-bharat-logo-edited-05.png",
    primaryTextColor: "#000000",
    secondaryTextColor: "#DE1F27",
    secondaryTextBackground: "#d8f9ee",
    primaryButtonBackground: "#ED3693;",
    primaryButtonTextColor: "#ffffff",
    secondaryButtonBackground: "#ffffff",
    secondaryButtonTextColor: "#000000d9",
    secondaryButtonBorderColor: "#1890ff",
    buttonDisabledBackground: "#d4d4d4",
    buttonDisabledTextColor: "#ffffff",
    cardBackground: "#F4F8FC",
    warningCardBackground: "#FFF2DC",
    tileBackground: "#F3F3F3",
    errorColor: "#ff6d55",
    focusColor: "#DE1F27",
    focusBackground: "#DE1F2720",
  },
  ALIGNBOOKS: {
    companyURL: "//logo.clearbit.com/alignbooks.com/",
    primaryTextColor: "#000000",
    secondaryTextColor: "#13479f",
    secondaryTextBackground: "#d8f9ee",
    primaryButtonBackground: "#13479f",
    primaryButtonTextColor: "#ffffff",
    secondaryButtonBackground: "#ffffff",
    secondaryButtonTextColor: "#000000d9",
    secondaryButtonBorderColor: "#1890ff",
    buttonDisabledBackground: "#d4d4d4",
    buttonDisabledTextColor: "#ffffff",
    cardBackground: "#F4F8FC",
    warningCardBackground: "#FFF2DC",
    tileBackground: "#F3F3F3",
    errorColor: "#ff6d55",
    focusColor: "#13479f",
    focusBackground: "#13479f20",
  },
  ABLEJOBS: {
    companyURL: "https://ablejobs.co/static/media/applogo.66a28e54.svg",
    primaryTextColor: "#444444",
    secondaryTextColor: "#13479f",
    secondaryTextBackground: "#d8f9ee",
    primaryButtonBackground: "#4285f4",
    primaryButtonTextColor: "#ffffff",
    secondaryButtonBackground: "#ffffff",
    secondaryButtonTextColor: "#000000d9",
    secondaryButtonBorderColor: "#1890ff",
    buttonDisabledBackground: "#d4d4d4",
    buttonDisabledTextColor: "#ffffff",
    cardBackground: "#F4F8FC",
    warningCardBackground: "#FFF2DC",
    tileBackground: "#F3F3F3",
    errorColor: "#ff6d55",
    focusColor: "#4285f4",
    focusBackground: "#4285f420",
  },
  INDIAMART: {
    companyURL: "https://upload.wikimedia.org/wikipedia/en/4/4a/Indiamart-new.png",
    primaryTextColor: "#333333",
    secondaryTextColor: "#19a598",
    secondaryTextBackground: "#d8f9ee",
    primaryButtonBackground: "#19a598",
    primaryButtonTextColor: "#ffffff",
    secondaryButtonBackground: "#ffffff",
    secondaryButtonTextColor: "#000000d9",
    secondaryButtonBorderColor: "#1890ff",
    buttonDisabledBackground: "#d4d4d4",
    buttonDisabledTextColor: "#ffffff",
    cardBackground: "#F4F8FC",
    warningCardBackground: "#FFF2DC",
    tileBackground: "#F3F3F3",
    errorColor: "#ff6d55",
    focusColor: "#4285f4",
    focusBackground: "#4285f420",
  },
};
