import {
  Head,
  Wrapper,
  Content,
  InputWithLabel,
  InputContainer,
  Button,
  ChipRadio,
} from "../../Common";
import { MdPerson, MdLocationOn } from "react-icons/md";
import DOB from "../../DOB";
import DropDown from "../../DropDown";
import Context from "../../../../contexts/AppContext";

const genderList = ["Female", "Male", "Other"];

const CompanyDetails = ({
  changeStory,
}: {
  changeStory: (hash: string) => any;
}) => {
  const context = Context();
  return (
    <Wrapper>
      <Content>
        <Head>Company Details</Head>
        <InputContainer>
          <InputWithLabel
            label="Company Name"
            placeholder="Enter Company Name"
            errorText="Invalid Name"
            value={"Company-X"}
            maxLength={30}
            onChange={() => {}}
            leftIcon={<MdPerson />}
            error={false}
          />

          <DropDown
            label="Sector"
            default="Private limited"
            onChange={(e) => console.log(e)}
            list={[
              "Propretorship",
              "Partnership",
              "Private limited",
              "Public Limited",
            ]}
          />

          <DropDown
            label="Constitution"
            default="Manufacturing"
            onChange={(e) => console.log(e)}
            list={["Manufacturing", "Services", "Trading", "Other"]}
          />

          <DropDown
            label="Loan Reason"
            default="Asset Purchase"
            onChange={(e) => console.log(e)}
            list={[
              "Asset Purchase",
              "Capital Infusion",
              "Working Capital",
              "Other",
            ]}
          />

          <DOB label="Date Of Incorporation" onChange={() => {}} />
        </InputContainer>
        <div style={{ margin: "10px 0", width: "100%" }}>
          <Button onClick={() => changeStory("Pan")}>Next</Button>
        </div>
      </Content>
    </Wrapper>
  );
};

export default CompanyDetails;
