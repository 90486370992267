import { Wrapper, Content } from "../Common";
import { useContext } from "react";
import { ThemeContext } from "styled-components";
import YourLogo from "../images/yourlogo.png";

const ThankYou = ({ changeStory }: { changeStory: (hash: string) => any }) => {
  const theme = useContext(ThemeContext);

  console.log("hello worlds");
  return (
    <Wrapper>
      <Content>
        <div style={{ marginTop: "53%" }}>
          <img
            src={theme.companyURL ? theme.companyURL : YourLogo}
            alt="company"
            style={{ width: "65%", display: "block", margin: "0 auto" }}
          />
        </div>
        <div style={{ color: "#24ca7a", fontWeight: 700, fontSize: "40px" }}>
          Thank You
        </div>
      </Content>
    </Wrapper>
  );
};

export default ThankYou;
