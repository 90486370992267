import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "styled-components";
import Lottie from "react-lottie";
import coin_drop from "../images/lottie/coin-drop.json";
import Context from "../../../contexts/AppContext";

import styled from "styled-components";
import {
  Head,
  Wrapper,
  Content,
  SubText,
  Card,
  Chip,
  SmallText,
  StatusHead,
  Row,
  HL,
  SecondaryButton
} from "../Common";

// Dashboard also has a loading state

const LoanDetails = ({
  changeStory,
}: {
  changeStory: (hash: string) => any;
}) => {
  const theme = useContext(ThemeContext);
  const context = Context();

  const Key = styled(SubText)`
    width: 30%;
  `;
  const Value = styled(SubText)`
    padding-left: 5px;
  `;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: coin_drop,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Wrapper>
      <Content>
        <Head>Loan Approved</Head>
        <div style={{ width: "100%" }}>
          <Lottie options={defaultOptions} width="45%" />
        </div>
        <StatusHead style={{ color: theme.secondaryTextColor }}>
          <b> Congratulations!</b>
        </StatusHead>
        <SmallText style={{ textAlign: "center", marginTop: "5px" }}>
          Your loan has been approved, amount will be processed within 1 hour.
        </SmallText>
        <div style={{ marginTop: "10px", width: "100%" }}>
          <Card>
            <div style={{ padding: "15px", width: "100%" }}>
              <Row style={{ width: "100%", alignItems: "flex-start" }}>
                <div>
                  <StatusHead
                    style={{
                      color: theme.secondaryTextColor,
                      fontSize: "19px",
                    }}
                  >
                    ₹ {context.eligibleAmount}
                  </StatusHead>
                  <Chip
                    color={theme.secondaryTextColor}
                    background={theme.secondaryTextBackground}
                  >
                    {context.lender}
                  </Chip>
                </div>
                <div>
                  <SmallText>
                    Interest: <b>X% per month</b>
                  </SmallText>
                  <SmallText>
                    Tenure: <b> {context.tenure} months</b>
                  </SmallText>
                </div>
              </Row>
              <Row style={{ marginTop: "15px" }}>
                  <SubText>Principal amount</SubText> <SubText>₹ {context.eligibleAmount}</SubText>
              </Row>
              <Row>
                <SubText>Processing Fee</SubText> <SubText>- ₹ 100</SubText>
              </Row>
              <Row>
                <SubText>GST on processing fee (18%)</SubText>{" "}
                <SubText>- ₹ {(100 * 18) / 100}</SubText>
              </Row>
              <HL />
              <Row>
                <SubText>
                  <b>Total Disbursed amount</b>
                </SubText>{" "}
                <SubText>
                  <b> ₹ {Number(context.eligibleAmount.replace(/,/g,'')) - 100 - (100 * 18) / 100}</b>
                </SubText>
              </Row>
            </div>
          </Card>
          <SubText style={{ marginTop: "10px" }}>
            <b> Selected Bank Account</b>
          </SubText>
          <Card style={{ marginTop: "5px", marginBottom: "10px" }}>
            <div style={{ padding: "15px", width: "100%" }}>
              <Row style={{ justifyContent: "flex-start" }}>
                <Key>Acc No.</Key>:
                <Value
                  style={{
                    fontSize: "13px",
                    display: "flex",
                    alignItems: "center",
                    width: "60%",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <b> XXXXXXXXX3421 </b>
                    {
                      <SmallText style={{ color: "#24CA7A" }}>
                        Verified
                      </SmallText>
                    }
                  </div>
                </Value>
              </Row>
              <Row style={{ justifyContent: "flex-start" }}>
                <Key>Bank</Key>:
                <Value>
                  <b>SBI</b>
                </Value>
              </Row>
              <Row style={{ justifyContent: "flex-start" }}>
                <Key>Branch</Key>:
                <Value>
                  <b>Branch Name</b>
                </Value>
              </Row>
              <Row style={{ justifyContent: "flex-start" }}>
                <Key>IFSC</Key>:
                <Value>
                  <b>SINB0008483</b>
                </Value>
              </Row>
            </div>
          </Card>
        </div>

        <div style={{ margin: "10px 0", width: "100%", paddingBottom:"10px"}}>
          <SecondaryButton onClick={()=>changeStory("Repayment")}>
            View Payment Options
          </SecondaryButton>
        </div>
      </Content>
    </Wrapper>
  );
};

export default LoanDetails;
