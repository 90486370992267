import React, { useContext, useState, useEffect, useRef } from "react";
import Lottie from "react-lottie";
import Check from "../images/lottie/check.json";

import Context from "../../../contexts/AppContext";

import {
  Wrapper,
  Content,
  Head,
  SubText,
  SmallText,
  Button,
  StatusHead,
} from "../Common";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: Check,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Done = ({ changeStory }: { changeStory: (hash: string) => any }) => {
  const context = Context();
  const [isAgree, setIsAgree] = useState(true);
  const [showDown, setShowDown] = useState(false);
  const endRef = useRef<HTMLDivElement>(document.createElement("div"));
  const scrollToBottom = () => {
    setShowDown(false);
    endRef!.current.scrollIntoView({ behavior: "smooth" });
  };

  

  useEffect(() => {
    const onScroll = () => {
      console.log("Hello");
      document.removeEventListener("scroll", onScroll);
    };

    document.addEventListener("scroll", onScroll);
    return () => {
      document.removeEventListener("scroll", onScroll);
    };
  }, []);

  const buttonDisabled = !isAgree;
  return (
    <Wrapper>
      <Content>
        <div style={{ width: "100%", marginTop: "20px" }}>
          <Lottie options={defaultOptions} width="90%" />
        </div>
        <Head style={{ alignSelf: "center" }}>₹ 55,000</Head>

        <StatusHead style={{ alignSelf: "center" }}>
          Thank you for your order.
        </StatusHead>
      </Content>
    </Wrapper>
  );
};

export default Done;
