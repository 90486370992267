import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "styled-components";
import Lottie from "react-lottie";
import coin_drop from "../../images/lottie/coin-drop.json";
import Context from "../../../../contexts/AppContext";
import FinBox from "../../images/finboxDark.svg";

import styled from "styled-components";
import {
  Head,
  Wrapper,
  Content,
  SubText,
  Card,
  Chip,
  SmallText,
  StatusHead,
  Row,
  HL,
  SecondaryButton,
} from "../../Common";

// Dashboard also has a loading state

const CreditLineApproved = ({
  changeStory,
}: {
  changeStory: (hash: string) => any;
}) => {
  const theme = useContext(ThemeContext);
  const context = Context();

  const Key = styled(SubText)`
    width: 30%;
  `;
  const Value = styled(SubText)`
    padding-left: 5px;
  `;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: coin_drop,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Wrapper>
      <Content>
        <Head>Credit Line Activated</Head>
        <div style={{ width: "100%" }}>
          <Lottie options={defaultOptions} width="45%" />
        </div>
        <StatusHead style={{ color: theme.secondaryTextColor }}>
          <b> Congratulations!</b>
        </StatusHead>
        <SmallText style={{ textAlign: "center", marginTop: "5px" }}>
          Your credit line has been activated. You can start using it at the checkout page.
        </SmallText>
        
        <div style={{ marginTop: "10px", width: "100%" }}>
          <Card>
            <div style={{ padding: "15px", width: "100%" }}>
              <Row style={{ width: "100%", alignItems: "flex-start" }}>
                <div>
                  <StatusHead
                    style={{
                      color: theme.secondaryTextColor,
                      fontSize: "19px",
                    }}
                  >
                    ₹ {context.eligibleAmount}
                    <span
                      style={{
                        marginLeft: "7px",
                        fontWeight: 500,
                        fontSize: "14px",
                      }}
                    >
                      Credit Line
                    </span>
                  </StatusHead>
                </div>
              </Row>
              <Row>
                <Chip
                  color={theme.secondaryTextColor}
                  background={theme.secondaryTextBackground}
                >
                  Lender X
                </Chip>
                <SmallText style={{marginBottom:"-6px"}}>
                  Powered by
                  <img
                    style={{ height: "18px", marginBottom: "-6px" }}
                    src={FinBox}
                  />
                </SmallText>
              </Row>
            </div>
          </Card>
        </div>

        <div style={{ margin: "10px 0", width: "100%", paddingBottom: "10px" }}>
          <SecondaryButton onClick={() => changeStory("CheckOut")}>
            View Checkout Page
          </SecondaryButton>
        </div>
      </Content>
    </Wrapper>
  );
};

export default CreditLineApproved;
