import styled from "styled-components";
import React, { useState, useContext, memo, useEffect } from "react";
import { MdError, MdDone } from "react-icons/md";
import { BsChevronRight } from "react-icons/bs";
import Slider from "react-input-slider";
import { ThemeContext } from "styled-components";

export const PhoneWrapper = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 300px;
  font-family: "Red Hat Display";
  @media screen and (max-width: 600px) {
    width: 100%;
    flex-direction: column;
    padding: 20px 0;
  }
`;

export const PhoneContainer = styled.div`
  border: 40px solid #211e32;
  position: relative;
  border-width: 50px 7px;
  border-radius: 40px;
  transition: all 0.5s ease;
  width: 330px;
  height: 550px;
  background: #211e32;
  box-shadow: 0px 3px 0 #110f1d, 0px 4px 0 #110f1d, 0px 5px 0 #110f1d,
    0px 7px 0 #110f1d, 0px 10px 20px #110f1d60;
  &:after {
    content: "";
    display: block;
    width: 35px;
    height: 35px;
    position: absolute;
    left: 50%;
    bottom: -65px;
    transform: translate(-50%, -50%);
    background: #474261;
    border-radius: 50%;
  }
  &:before {
    content: "";
    display: block;
    width: 60px;
    height: 5px;
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #474261;
    border-radius: 10px;
  }
`;
export const PhoneContent = styled.div`
  border-radius: 7px;
  width: 100%;
  height: 100%;
  background: white;
  overflow-y: auto;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 90%;
  width: 87%;
  position: relative;
  max-width: 787px;
`;
export const Card = styled.div`
  /* padding:20px; */
  background: ${(props) => props.theme.cardBackground};
  border-radius: 10px;
  display: flex;
  align-items: center;
  width: 100%;
`;
export const TopBar = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 30px;
  margin-bottom: 10px;
`;

export const Head = styled.div`
  font-weight: 700;
  font-size: 20px;
  color: ${(props) => props.theme.primaryTextColor};
  align-self: flex-start;
  line-height: 29px;
  letter-spacing: 1px;
  @media (max-width: 398px) {
    font-size: 20px;
    line-height: 25px;
  }
`;

export const SecHead = styled.div`
  font-weight: 600;
  font-size: 18px;
  color: ${(props) => props.theme.primaryTextColor};
  align-self: flex-start;
  line-height: 29px;
  letter-spacing: 1px;
  @media (max-width: 398px) {
    font-size: 18px;
    line-height: 25px;
  }
`;
export const StatusHead = styled.div`
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  font-weight: 700;
  color: ${(props) => props.theme.primaryTextColor};
  @media (max-width: 398px) {
    font-size: 14px;
    line-height: 15px;
  }
`;
export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 1px;
`;
export const HL = styled.div`
  border-top: 1px solid ${(props) => props.theme.primaryTextColor};
  height: 1px;
  margin: 5px 0;
  width: 100%;
`;
export const SubText = styled.div`
  font-size: 11px;
  color: ${(props) => props.theme.primaryTextColor};
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.6px;
  @media (max-width: 398px) {
    font-size: 11px;
    line-height: 15px;
  }
`;

export const Tab = styled.div<{ selected: boolean }>`
  font-size: 12px;
  padding: 5px;
  margin-right: 10px;
  color: ${(props) =>
    props.selected ? props.theme.primaryTextColor : "#777777"};
  border-bottom: ${(props) =>
    props.selected ? "2px solid #24CA7A" : "2px solid white"};
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0.6px;
  margin-right: 10px;
  @media (max-width: 398px) {
    font-size: 12px;
    line-height: 12px;
  }
`;

export const SmallText = styled.div`
  font-size: 10px;
  color: ${(props) => props.theme.primaryTextColor};
  font-weight: 300;
  line-height: 14px;
  letter-spacing: 0.6px;
  @media (max-width: 398px) {
    font-size: 10px;
    line-height: 15px;
  }
`;

export const SectionLabel = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
  color: ${(props) => props.theme.primaryTextColor};
  align-self: flex-start;
  letter-spacing: 1px;
  @media (max-width: 398px) {
    font-size: 14px;
  }
`;

export const Chip = styled.div<{ background?: string }>`
  font-size: 10px;
  line-height: 19px;
  width: fit-content;
  display: flex;
  align-items: center;
  margin-top: 10px;
  border-radius: 17px;
  padding: 1px 10px;
  color: ${(props) => props.color};
  background: ${(props) => props.background};
  @media (max-width: 398px) {
    font-size: 10px;
    line-height: 15px;
  }
`;

export const InputContainer = styled.div`
  justify-self: flex-start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-top: 1.2rem;
`;

export const Label = memo(styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 28px;
  letter-spacing: 1px;
  color: ${(props) => props.theme.primaryTextColor};
  align-self: flex-start;
  @media (max-width: 398px) {
    font-size: 10px;
    line-height: 25px;
  }
`);
export const Input = styled.input`
  border: none;
  outline: none;
  width: calc(100% - 35px);
  padding-left: 35px;
  background: ${(props) => props.theme.tileBackground};
  border-radius: 10px;
  font-size: 12px;
  color: ${(props) => props.theme.primaryTextColor};
  line-height: 37px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  @media (max-width: 398px) {
    font-size: 10px;
    line-height: 35px;
  }
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
`;

export const Tile = styled.div`
  width: calc(100% - 35px);
  padding-left: 35px;
  background: ${(props) => props.theme.tileBackground};
  border-radius: 10px;
  font-size: 12px;
  color: ${(props) => props.theme.primaryTextColor};
  line-height: 37px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  @media (max-width: 398px) {
    font-size: 10px;
    line-height: 35px;
  }
`;

export const InputLeftIcon = styled.div`
  position: absolute;
  color: #a4a4a4;
  top: 0px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px;
`;
export const InputRightIcon = styled.div`
  position: absolute;
  color: #a4a4a4;
  right: 0px;
  top: 0px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const InputErrorIcon = styled.div`
  position: absolute;
  color: ${(props) => props.theme.errorColor};
  right: 5px;
  top: 0px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1.3rem;
`;
export const ToolTip = styled.div`
  display: inline-block;
  padding: 2px 8px;
  border: 1px solid ${(props) => props.theme.errorColor};
  font-size: 11px;
  background: white;
  border-radius: 10px;
  color: ${(props) => props.theme.errorColor};
  letter-spacing: 0.8px;
  position: absolute;
  right: 4px;
  bottom: -7px;
  z-index: 3;
  width: max-content;
  &:before {
    border: solid;
    border-color: ${(props) => props.theme.errorColor} transparent;
    border-width: 0px 5px 6px 5px;
    content: "";
    right: 5px;
    bottom: 18px;
    position: absolute;
  }
  @media (max-width: 398px) {
    font-size: 9px;
  }
`;

export const WithLabel = (props: { children: JSX.Element[] }) => {
  return (
    <div
      style={{
        width: "100%",
        position: "relative",
        padding: ".3rem 0 ",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center",
      }}
    >
      {props.children}
    </div>
  );
};

export const InputWithLabel = memo(
  (props: {
    label?: string;
    placeholder?: string;
    maxLength?: number;
    value?: string;
    onChange?: any;
    type?: string;
    error?: boolean;
    tick?: boolean;
    leftIcon?: JSX.Element | string;
    testId?: string;
    errorText?: string;
    style?: React.CSSProperties;
  }) => {
    return (
      <WithLabel>
        <Label
          style={{
            color: props.style && props.style.color ? props.style.color : "",
          }}
        >
          {props.label}
        </Label>
        <div style={{ width: "100%", position: "relative" }}>
          <InputLeftIcon>{props.leftIcon}</InputLeftIcon>
          <Input
            value={props.value}
            placeholder={props.placeholder}
            onChange={(e) => {
              if (props.maxLength) {
                if (e.target.value.length <= props.maxLength) props.onChange(e);
              } else props.onChange(e);
            }}
            type={props.type}
            data-testid={props.testId}
            style={props.style}
          />
          <InputErrorIcon>
            {props.error ? (
              <MdError />
            ) : props.tick ? (
              <MdDone style={{ color: "#24CA7A", fontSize: "1.2rem" }} />
            ) : (
              ""
            )}
          </InputErrorIcon>
        </div>
        {props.error && props.errorText ? (
          <ToolTip>{props.errorText}</ToolTip>
        ) : (
          <></>
        )}
      </WithLabel>
    );
  }
);

export const TileWithLabel = memo(
  (props: {
    label: string;
    onClick: any;
    header?: string;
    leftIcon?: JSX.Element;
    rightIcon?: JSX.Element;
  }) => {
    return (
      <WithLabel>
        <Label>{props.label}</Label>
        <div style={{ width: "100%", position: "relative" }}>
          <InputLeftIcon>{props.leftIcon}</InputLeftIcon>
          <Tile onClick={() => props.onClick()}>{props.header}</Tile>
          <InputRightIcon>{props.rightIcon}</InputRightIcon>
        </div>
      </WithLabel>
    );
  }
);

export const SliderWithLabel = memo(
  (props: {
    x: number;
    onChange: any;
    label: string;
    axis: "x" | "y" | "xy" | undefined;
    xstep: number;
    xmin: number;
    xmax: number;
  }) => {
    const theme = useContext(ThemeContext);
    useEffect(() => {
      if (!props.x) props.onChange(0);
    }, []);
    return (
      <WithLabel>
        <Label>{props.label}</Label>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <Slider
            axis={props.axis}
            xstep={props.xstep}
            xmin={props.xmin}
            xmax={props.xmax}
            x={props.x}
            onChange={({ x }) => props.onChange(x)}
            styles={{
              active: {
                backgroundColor: theme.primaryButtonBackground,
              },
            }}
          />
          <SubText>{props.x}</SubText>
        </div>
      </WithLabel>
    );
  }
);

export const Button = styled.button`
  border: none;
  color: ${(props) =>
    props.disabled
      ? props.theme.buttonDisabledTextColor
      : props.theme.primaryButtonTextColor};
  width: 100%;
  background: ${(props) =>
    props.disabled
      ? props.theme.buttonDisabledBackground
      : props.theme.primaryButtonBackground};
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 600;
`;

export const SecondaryButton = styled.button`
  border: 1px solid ${(props) => props.theme.secondaryButtonBorderColor};
  color: ${(props) => props.theme.secondaryButtonTextColor};
  width: 100%;
  background: ${(props) => props.theme.secondaryButtonBackground};
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 600;
`;

export const ChipRadio = memo(
  (props: {
    active?: boolean;
    default?: string;
    label?: string;
    list: any[];
    onChange?: any;
    error?: boolean;
  }) => {
    const Chipped = styled(Chip)<{ active: boolean }>`
      color: ${(props) =>
        props.active ? props.theme.focusColor : props.theme.primaryTextColor};
      background: ${(props) =>
        props.active
          ? props.theme.focusBackground
          : props.theme.tileBackground};
      font-weight: ${(props) => (props.active ? 600 : 400)};
      height: 35px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      letter-spacing: 1px;
      width: 85px;
      cursor: pointer;
      margin-right: 15px;
      @media (max-width: 398px) {
        font-size: 10px;
      }
    `;
    const [selected, setSelected] = useState(props.default);
    const theme = useContext(ThemeContext);
    return (
      <WithLabel>
        <Label>{props.label}</Label>
        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
          {props.list.map((item: any, index: number) => (
            <Chipped
              key={index}
              active={(item === selected) as boolean}
              // onClick={() => {
              //   setSelected(item);
              //   props.onChange(item);
              // }}
            >
              {item}
            </Chipped>
          ))}
          {props.error ? (
            <div style={{ position: "relative" }}>
              <MdError
                style={{
                  color: theme.errorColor,
                  fontSize: "1.3rem",
                  marginTop: "15px",
                  paddingRight: "5px",
                }}
              />
              <ToolTip style={{ bottom: "-18px" }}>Select one</ToolTip>
            </div>
          ) : (
            ""
          )}
        </div>
      </WithLabel>
    );
  }
);

export const List = styled.div`
  margin-top: 20px;
  max-height: 400px;
  min-height: 300px;
  width: 100%;
  @media (max-width: 768px) {
    max-height: 65vh;
  }
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-button {
    width: 0;
  }

  &::-webkit-scrollbar-track {
    background: #fcfcfc;
  }

  &::-webkit-scrollbar-thumb {
    background: #737373;
  }
  scrollbar-width: thin;
  scrollbar-color: #737373 #fcfcfc;
`;

const BankContainer = styled.div`
  background: #f3f3f3;
  padding: 10px 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

export const BankRow = (props: {
  onBankSelected?: any;
  src: string;
  full_name: string;
  selected?: boolean;
}) => {
  return (
    <div style={{ marginTop: 20 }}>
      <BankContainer
        onClick={props.onBankSelected}
        style={
          props.selected
            ? { border: "1px solid #24CA7A", cursor: "pointer" }
            : {}
        }
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={props.src} width={17} alt="" />

          <div
            style={{ color: "#777777", fontSize: "14px", marginLeft: "20px" }}
          >
            {props.full_name}
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <BsChevronRight style={{ color: "#b3b3b3", fontSize: "14px" }} />
        </div>
      </BankContainer>
    </div>
  );
};

const BCInput = styled.div`
  display: flex;
  border-radius: 3px;
  margin-top: 8px;
  align-items: center;
  border: 1px solid #ddd;
`;
export const LableAndInput = (props:{label:string;icon:any;value:string,type:string}) => {
  return (
    <div>
      <Label>{props.label}</Label>
      <BCInput>
        {props.icon}
        <input
          autoComplete="new-password"
          style={{
            border: "none",
            padding: 10,
            width: "100%",
            outline: "none",
          }}
          value={props.value}
          type={props.type}
        />
      </BCInput>
    </div>
  );
};
