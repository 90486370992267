import React, { useContext, useState, useEffect, useRef } from "react";
import Context from "../../../contexts/AppContext";
import { ThemeContext } from "styled-components";

import {
  Wrapper,
  Content,
  Head,
  SubText,
  SmallText,
  Button,
  Label,
  StatusHead,
} from "../Common";
import { formatAmount } from "../../utils";
import { MdNavigateNext } from "react-icons/md";
import { BsCreditCard } from "react-icons/bs";

const Payment = ({ changeStory }: { changeStory: (hash: string) => any }) => {
  const context = Context();
  const theme = useContext(ThemeContext);
  const amount = 18000;
  const total =
    Number(context.eligibleAmount.replace(/,/g, "")) - 100 - (100 * 18) / 100;

  return (
    <Wrapper>
      <Content>
        <Head
          style={{
            marginBottom: "30px",
          }}
        >
          RePay Credits
        </Head>

        <div
          style={{
            border: "1px solid grey",
            width: "100%",
            borderRadius: "10px",
            display: "flex",
            boxSizing: "border-box",
            padding: "10px",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "10px",
          }}
          onClick={() => changeStory("ThankYou")}
        >
          <div
            style={{
              height: "max-content",
              display: "flex",
              alignItems: "center",
            }}
          >
            <StatusHead>UPI</StatusHead>

            <img
              style={{
                height: "10px",
                marginLeft: "10px",
                marginBottom: "-3px",
              }}
              src="http://logo.clearbit.com/npci.org.in/"
            />
          </div>
          <MdNavigateNext />
        </div>

        <div
          style={{
            border: "1px solid grey",
            width: "100%",
            borderRadius: "10px",
            display: "flex",
            boxSizing: "border-box",
            padding: "10px",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "10px",
          }}
          onClick={() => changeStory("ThankYou")}
        >
          <div
            style={{
              height: "max-content",
              display: "flex",
              alignItems: "center",
            }}
          >
            <StatusHead>Credit Card</StatusHead>
          </div>
          <MdNavigateNext />
        </div>

        <div
          style={{
            border: "1px solid grey",
            width: "100%",
            borderRadius: "10px",
            display: "flex",
            boxSizing: "border-box",
            padding: "10px",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "10px",
          }}
          onClick={() => changeStory("ThankYou")}
        >
          <div
            style={{
              height: "max-content",
              display: "flex",
              alignItems: "center",
            }}
          >
            <StatusHead>Debit Card</StatusHead>
          </div>
          <MdNavigateNext />
        </div>
      </Content>
    </Wrapper>
  );
};

export default Payment;
