import React, { useState, memo, useCallback, useEffect } from "react";
import { PhoneWrapper, PhoneContainer, PhoneContent } from "./Common";
import Context from "../../contexts/AppContext";
import ContactDetails from "./ContactDetails";
import { ScrollStory } from "../utils";
import ThemeEditor from "./ThemeEditor";
import BasicInfo from "./screens/BasicInfo";
import Start from "./screens/Start";
import Permission from "./screens/Permission";
import Pan from "./screens/Pan";
import PanOtp from "./screens/PanOtp";
import Experian from "./screens/Experian";
import LoanOffer from "./screens/LoanOffer";
import Dashboard from "./screens/Dashboard";
import KYC from "./screens/KYC";
import PennyDrop from "./screens/PennyDrop";
import Esign from "./screens/Esign";
import EsignOTP from "./screens/EsignOTP";
import CreditLineApproved from "./screens/CreditLine/CreditLineApproved";
import CheckOut from "./screens/CreditLine/CheckOut";
import CreditLinePayment from "./screens/CreditLine/CreditLinePayment";
import CreditLineSelectMode from "./screens/CreditLine/CreditLineSelectMode";
import Done from "./screens/Done";

type Props = {
  setCurrHash: React.Dispatch<React.SetStateAction<string>>;
  setPage: React.Dispatch<React.SetStateAction<string>>;
  page: string;
};

const Phone = ({ setCurrHash, setPage, page }: Props) => {
  const context = Context();
  const secret = "d23aca1f-8f49-4f0c-9579-3439ab5cd3b3";
  const [hasSecret, setHasSecret] = useState(false);

  useEffect(() => {
    context.setLoanType("CREDITLINE");

    const params = new URLSearchParams(window.location.search);
    setHasSecret(params.get("x") === secret);
    // eslint-disable-next-line
  }, []);

  const changeStory = useCallback((hash: string): void => {
    setPage(hash);
    let story: string;
    switch (hash) {
      case "DashboardKYC":
        story = "KYC";
        break;
      case "DashboardPennyDrop":
        story = "PennyDrop";
        break;
      case "DashboardSign":
        story = "Esign";
        break;
      default:
        story = hash;
        break;
    }

    setCurrHash(story);
    setTimeout(() => {
      ScrollStory(story);
    }, 100);
  }, []);

  // const changeStory =
  const Screen: any = {
    Start: <Start changeStory={changeStory} />,
    Permission: <Permission changeStory={changeStory} />,
    BasicInfo: <BasicInfo changeStory={changeStory} />,
    Pan: <Pan changeStory={changeStory} />,
    PanOtp: <PanOtp changeStory={changeStory} />,
    Experian: <Experian changeStory={changeStory} />,
    LoanOffer: <LoanOffer changeStory={changeStory} />,
    DashboardKYC: <Dashboard stage="KYC" changeStory={changeStory} />,
    KYC: <KYC changeStory={changeStory} />,
    DashboardPennyDrop: (
      <Dashboard stage="PENNYDROP" changeStory={changeStory} />
    ),
    PennyDrop: <PennyDrop changeStory={changeStory} />,
    DashboardSign: <Dashboard stage="SIGN" changeStory={changeStory} />,
    Esign: <Esign changeStory={changeStory} />,
    EsignOTP: <EsignOTP changeStory={changeStory} />,
    CreditLineApproved: <CreditLineApproved changeStory={changeStory} />,
    CheckOut: <CheckOut changeStory={changeStory} />,
    CreditLinePayment: <CreditLinePayment changeStory={changeStory} />,
    CreditLineSelectMode: <CreditLineSelectMode changeStory={changeStory} />,
    Done: <Done changeStory={changeStory} />,
  };

  return (
    <PhoneWrapper>
      <PhoneContainer>
        {hasSecret && <PhoneContent>{Screen[page]}</PhoneContent>}
      </PhoneContainer>
      {/* <ThemeEditor /> */}
      {!hasSecret && <ContactDetails />}
    </PhoneWrapper>
  );
};

export default memo(Phone);
