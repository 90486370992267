import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router";
import axios from "axios";
const Overlay = styled.div`
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 200;
  backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  background: white;
  width: 45%;
  height: 95%;
  box-sizing: border-box;
  padding: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 800px) {
    width: 85%;
    height: 95%;
  }
`;

const Head = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;

  /* identical to box height */
  letter-spacing: 0.006em;

  color: #03363d;
`;
const Label = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.006em;
  color: #17494d;
  margin-top: 15px;
  margin-bottom: 5px;
`;

const Input = styled.input`
  background: #ffffff;
  border: 1px solid #dee2e9;
  box-sizing: border-box;
  width: 100%;
  height: 35px;
  padding: 5px;
`;

const Button = styled.div`
  background: #24ca7a;
  border: none;
  padding: 10px 50px;
  color: white;
  width: max-content;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.006em;
  margin-top: 30px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
`;

function ValidateEmailRegex(mail) {
  console.log(mail);
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(mail).toLowerCase());
}

function isCommonEmail(mail) {
  console.log(mail);
  var reg =
    /(@gmai\.|@test\.|@email\.|@gmail\.|@yahoo\.|@yahoomail\.|@aol\.|@rediffmail\.|@live\.|@outlook\.|@me\.|@msn\.|@ymail\.|@hotmail\.|@rocketmail\.)/i;
  let isCommon = reg.test(mail);
  if (isCommon) {
    return true;
  } else {
    return false;
  }
}

const ContactDetails = () => {
  const [visible, setVisible] = useState(true);
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [org, setOrg] = useState("");
  const [designation, setDesignation] = useState("");
  const [error, setError] = useState("");
  const [newsletter, setNewsletter] = useState(true);
  const [showMsg, setShowMsg] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const param = urlParams.get("i");
    const company = urlParams.get("client");
    if (param === "edvskjckjsansj") {
      localStorage.setItem("xdfhib", "ghyi");
      setVisible(false);
      if (company) history.push("/credit-line?client=" + company);
      else history.push("/credit-line");
    }
    if (localStorage.getItem("xdfhib") === "ghyi") {
      setVisible(false);
    }
  }, []);

  const ValidateEmail = () => {
    if (!ValidateEmailRegex(email)) {
      setError("Please Enter Valid  Email ID");
      console.log("not valid email");
      return false;
    }

    if (isCommonEmail(email)) {
      setError("Please Enter  Company Email ID");
      console.log("not company email");
      return false;
    }
    return true;
  };

  const validate = () => {
    return name && email && phone && org;
  };

  const submit = async () => {
    if (!validate()) {
      setError("All fields are required");
      return;
    } else {
      setError("");
    }

    if (!ValidateEmail()) return;
    else {
      setError("");
    }

    if (!/^\d{10}$/.test(phone)) {
      setError("Please enter a valid phone number");
      return;
    } else {
      setError("");
    }

    try {
      setSubmitting(true);
      await axios.post(
        "https://zkn6vxxf2h.execute-api.ap-south-1.amazonaws.com/prod/api",
        {
          name: name,
          email: email,
          phone: phone,
          company: org,
          ctaFrom: "CreditLine DEMO",
          products: ["Embedded Credit Line"],
        }
      );
      await axios.post(
        "https://9triu23cu3.execute-api.ap-south-1.amazonaws.com/default/subscription",
        {
          attributes: {
            FIRSTNAME: name,
            LASTNAME: lastName,
            COMPANY_NAME: org,
            DESIGNATION: designation,
            PHONE: phone,
            SIGNUPSOUR: "Creditline Demo",
            ctaFrom: "CreditLine DEMO",
            LEAD: true,
          },
          email,
          updateEnabled: false,
          listIds: [2],
          emailBlacklisted: !newsletter,
        }
      );
      setShowMsg(true);
    } catch (e) {
      console.error(e);
    }

    setSubmitting(false);
    return false;
  };

  if (!visible) return <></>;
  return (
    <Overlay>
      <Content style={{ display: "block", overflowY: "auto" }}>
        <div style={{ width: "75%" }}>
          {showMsg ? (
            <>
              <Head>Thank You!</Head>
              <p>
                Thanks for your interest in the FinBox Credit Line Demo. We will
                reach out to you soon
              </p>
            </>
          ) : (
            <>
              <Head>Sign up to access the demo</Head>
              <Label>First Name</Label>
              <Input value={name} onChange={(e) => setName(e.target.value)} />
              <Label>Last Name</Label>
              <Input
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
              <Label type="email" placeholder="Company Email">
                Email
              </Label>
              <Input value={email} onChange={(e) => setEmail(e.target.value)} />
              <Label type="number">Phone</Label>
              <Input value={phone} onChange={(e) => setPhone(e.target.value)} />
              <Label type="text">Organisation</Label>
              <Input value={org} onChange={(e) => setOrg(e.target.value)} />
              <Label type="text">Designation</Label>
              <Input
                value={designation}
                onChange={(e) => setDesignation(e.target.value)}
              />
              <br />
              <p style={{ fontSize: "14px", color: "red", height: "15px" }}>
                {error}
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <Input
                  type="checkbox"
                  name="newsletter-checkbox"
                  style={{ width: "10%", margin: 0 }}
                  defaultChecked
                  onChange={(e) => {
                    const checked = e.target.checked;
                    setNewsletter(checked);
                  }}
                />
                <Label
                  htmlFor="newsletter-checkbox"
                  style={{ margin: 0, marginLeft: 15 }}
                >
                  Subscribe to our newsletter
                </Label>
              </div>
              <Button onClick={submit} disabled={submitting}>
                {submitting ? "Submitting..." : "Sign Up"}
              </Button>
            </>
          )}
        </div>
      </Content>
    </Overlay>
  );
};

export default ContactDetails;
