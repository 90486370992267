import React, { useState, memo } from "react";
import { FaAddressCard } from "react-icons/fa";

import {
  Wrapper,
  Content,
  Head,
  InputWithLabel,
  InputContainer,
  Button,
  SmallText,
  SubText,
} from "../../Common";

const PanInput = ({ changeStory }: { changeStory: (hash: string) => any }) => {
  return (
    <Wrapper>
      <Content>
        <Head>Enter your PAN</Head>
        <InputContainer>
          <InputWithLabel
            placeholder="Enter Director's Pan"
            label="Director PAN"
            value={"AAAPZ1234C"}
            onChange={() => {}}
            leftIcon={<FaAddressCard />}
          />
          <InputWithLabel
            placeholder="Enter Company Pan"
            label="Company PAN"
            value={"ABCPZ4567D"}
            onChange={() => {}}
            leftIcon={<FaAddressCard />}
          />
          <InputWithLabel
            placeholder="Enter GSTIN"
            label="GSTIN"
            value={"GGGGG1314R"}
            onChange={() => {}}
            leftIcon={<FaAddressCard />}
          />
          <div style={{ display: "flex", marginTop: "13px" }}>
            <div style={{ paddingRight: "5px" }}>
              <input type="checkbox" checked={true} />
            </div>
            <SubText>
              I here by consent to FinBox being appointed as my authorised
              representative to receive my Credit Information from Bureau for
              the use of credit assessment and facilitation of credit/loan
              offers best suited to me
            </SubText>
          </div>
        </InputContainer>

        <div style={{ marginTop: "20px", width: "100%" }}>
          <SmallText>
            By continuing, you agree to
            <span
              style={{ color: "#24CA7A", fontWeight: "bold" }}
              onClick={() =>
                window.open("https://finbox.in/about/privacy-policy/")
              }
            >
              {" "}
              FinBox User Agreement.{" "}
            </span>
          </SmallText>
          <Button
            style={{ margin: "10px 0" }}
            onClick={() => changeStory("PanOtp")}
          >
            Create Profile
          </Button>
        </div>
      </Content>
    </Wrapper>
  );
};

export default memo(PanInput);
