import React, {
  useState,
  memo,
  useCallback,
  useContext,
  useEffect,
} from "react";
import { PhoneWrapper, PhoneContainer, PhoneContent } from "./Common";

import { ScrollStory } from "../utils";
import Context from "../../contexts/AppContext";
import ThemeEditor from "./ThemeEditor";
import Start from "./screens/Start";
import Permission from "./screens/Permission";
import BasicInfo from "./screens/BasicInfo";
import CompanyDetails from "./screens/Business/CompanyDetails";
import Pan from "./screens/Business/Pan";
import PanOtp from "./screens/PanOtp";
import Experian from "./screens/Experian";

import BankList from "./screens/Bankconnect/BankList";
import BankLogin from "./screens/Bankconnect/BankLogin";

import LoanOffer from "./screens/LoanOffer";
import Dashboard from "./screens/Dashboard";
import KYC from "./screens/KYC";
import PennyDrop from "./screens/PennyDrop";
import Esign from "./screens/Esign";
import EsignOTP from "./screens/EsignOTP";
import LoanApprovalInfo from "./screens/LoanApprovInfo";
import CredLinePay from "./screens/CredLinePay";
import CredLineRepay from "./screens/CredLineRepay";
import Repayment from "./screens/Repayment";
import Payment from "./screens/PaymentScreen";
import CredLineEmi from "./screens/CredLineEmi";
import ThankYou from "./screens/ThankYou";

type Props = {
  setCurrHash: React.Dispatch<React.SetStateAction<string>>;
  setPage: React.Dispatch<React.SetStateAction<string>>;
  page: string;
};

const Phone = ({ setCurrHash, setPage, page }: Props) => {
  const context = Context();

  useEffect(() => {
    context.setLoanType("BUSINESS");
  }, []);
  const changeStory = useCallback((hash: string): void => {
    setPage(hash);
    let story: string;
    switch (hash) {
      case "DashboardKYC":
        story = "KYC";
        break;
      case "DashboardPennyDrop":
        story = "PennyDrop";
        break;
      case "DashboardSign":
        story = "Esign";
        break;
      default:
        story = hash;
        break;
    }
   
    setCurrHash(story);
    setTimeout(() => {
      ScrollStory(story);
    }, 100);
  }, []);

  // const changeStory =
  const Screen: any = {
    Start: <Start changeStory={changeStory} />,
    Permission: <Permission changeStory={changeStory} />,
    BasicInfo: <BasicInfo changeStory={changeStory} />,
    CompanyDetails: <CompanyDetails changeStory={changeStory} />,
    Pan: <Pan changeStory={changeStory} />,
    PanOtp: <PanOtp changeStory={changeStory} />,
    Experian: <Experian changeStory={changeStory} />,

    BankList: <BankList changeStory={changeStory} />,
    BankLogin: <BankLogin changeStory={changeStory} />,

    LoanOffer: <LoanOffer changeStory={changeStory} />,
    DashboardKYC: <Dashboard stage="KYC" changeStory={changeStory} />,
    KYC: <KYC changeStory={changeStory} />,
    DashboardPennyDrop: (
      <Dashboard stage="PENNYDROP" changeStory={changeStory} />
    ),
    PennyDrop: <PennyDrop changeStory={changeStory} />,
    DashboardSign: <Dashboard stage="SIGN" changeStory={changeStory} />,
    Esign: <Esign changeStory={changeStory} />,
    EsignOTP: <EsignOTP changeStory={changeStory} />,
    LoanApprovalInfo: <LoanApprovalInfo changeStory={changeStory} />,
    CredLinePay: <CredLinePay changeStory={changeStory} />,
    CredLineRepay: <CredLineRepay changeStory={changeStory} />,
    Repayment: <Repayment changeStory={changeStory} />,
    Payment: <Payment changeStory={changeStory} />,
    ThankYou: <ThankYou changeStory={changeStory} />,
  };

  return (
    <PhoneWrapper>
      <PhoneContainer>
        <PhoneContent>{Screen[page]}</PhoneContent>
      </PhoneContainer>
      {/* <ThemeEditor /> */}
    </PhoneWrapper>
  );
};

export default memo(Phone);
