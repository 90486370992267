import React, { memo } from "react";
import styled, { ThemeContext } from "styled-components";
import { ScrollStory } from "./utils";
import { MdDone } from "react-icons/md";
import { AiFillExclamationCircle } from "react-icons/ai";
import FinboxLogo from "../images/finbox.svg";
import { FaGlobeAmericas, FaSyncAlt } from "react-icons/fa";

const StoryWrapper = styled.div`
  height: 100%;
  width: 50%;
  background: #211e32;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;
const StoryContainer = styled.div`
  height: 40vh;

  overflow-y: auto;
  width: 80%;

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-bottom: 100px;
  color: white;
`;

export const Circle = styled.div<{ status: string }>`
  border-radius: 50%;
  height: 12px;
  width: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid
    ${(props) => (props.status === "done" ? "#24CA7A" : "#9e9e9e")};
  color: white;
  font-size: 22px;
  background: #24ca7a;
`;

export const Line = styled.div<{ status: string; big: boolean | undefined }>`
  margin-top: 1px;
  margin-bottom: 1px;
  width: 1px;
  border-right: 1px
    ${(props) => (props.status === "done" ? "solid #24CA7A" : "dashed white")};
  height: ${(props) => (props.big ? "80px" : "45px")};
  /* background: #24CA7A; */
`;
export const Dot = styled.div`
  border-radius: 50%;
  height: 8px;
  width: 8px;
  background: white;
`;
export const Err = styled.div`
  color: ${(props) => props.theme.errorColor};
`;
const Head = styled.div`
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 1.1px;
`;

const SubHead = styled.div`
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.7px;
  display: inline-block;
  margin-top: 10px;
`;

const HL = styled.div`
  width: 80%;
  height: 1px;
  align-self: center;
  background: #00000015;
  margin: 0.8rem 0;
`;

const Button = styled.button`
  border: 1px solid white;
  color: white;
  width: max-content;
  padding: 0 10px;
  align-self: flex-start;
  background: none;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 600;
  margin-top: 10px;
  outline: none;
  &:hover {
    color: #211e32;
    background: white;
  }
`;

const SmallButton = styled.button`
  /* border: 1px solid ${(props) => props.theme.primaryButtonBackground}; */
  border: none;
  font-size: 12px;
  border-radius: 6px;
  /* color: ${(props) => props.theme.primaryButtonTextColor}; */
  color: white;
  background: #1d39a9;
  height: 27px;
  padding: 0 10px;
  margin: 3px 0px;
  font-weight: 600;
  outline: none;
`;

const stories = [
  {
    hash: "Start",
    head: "Start the Lending SDK",
    subhead: (
      <>
        Borrower clicks on <SmallButton>Banner</SmallButton> to start the loan
        journey
      </>
    ),
    status: "pending",
  },
  {
    hash: "Permission",
    head: "Grant Permission",
    subhead: "Borrower grants permission for SMS,LOCATION,DEVICE,APPS",
    status: "pending",
  },
  {
    hash: "BasicInfo",
    head: "Capture Borrower Details",
    subhead: "Borrower creates a Simple Profile.",
    status: "pending",
  },
  {
    hash: "CompanyDetails",
    head: "Capture Company Details",
    subhead: "Borrower creates a Simple Profile.",
    status: "pending",
  },
  {
    hash: "Pan",
    head: "Request Bureau Consent",
    subhead:
      "Request PAN and consent from the borrower to fetch Bureau credit score",
    status: "pending",
  },
  {
    hash: "PanOtp",
    head: "Mobile Verification",
    subhead:
      "Enter OTP to verify the mobile number that is registered with PAN",
    status: "pending",
  },
  {
    hash: "Experian",
    head: "Fetch Bureau Score",
    subhead:
      "Waits for Bureau score to be generated. This score along with FinBox Risk Score will be used to determine the loan eligibility",
    status: "pending",
  },
  {
    hash: "BankList",
    head: "Bank List",
    subhead: "Choose your bank",
    status: "pending",
  },
  {
    hash: "BankLogin",
    head: "Bank Login",
    subhead:
      "Login to your bank using Netbanking Credentials or upload bank statements",
    status: "pending",
  },
  {
    hash: "LoanOffer",
    head: "Show Loan Offer",
    subhead:
      "Show loan offer details with interest, tenure, and loan amount information",
    status: "pending",
  },
  {
    hash: "KYC",
    head: "Request KYC details",
    subhead:
      "The borrower has to upload a selfie, PAN, and address proof photo and await KYC approval. KYC approval is done in realtime with OCR and liveness check.",
    status: "pending",
  },
  {
    hash: "PennyDrop",
    head: "Verify Bank Account",
    subhead: "Borrower's disbursal account is verified with penny drop",
    status: "pending",
  },
  {
    hash: "Esign",
    head: "Sign Agreement",
    subhead: "Sign loan agreement on the app by giving borrower consent",
    status: "pending",
  },
  {
    hash: "LoanApprovalInfo",
    head: "View Approved loan",
    subhead:
      "This is the final step of the loan application, approved loan information is shown and is pending disbursal",
    status: "pending",
  },
  {
    hash: "Repayment",
    head: "Repay Credit",
    subhead: "EMI status and option to pay EMI's",
    status: "pending",
  },
  {
    hash: "Payment",
    head: "Payment Gateway",
    subhead: "Choose Payment Gateway to pay EMI",
    status: "pending",
  },
  {
    hash: "ThankYou",
    head: "Thank You Screen",
    subhead: "Your Journey is done",
    status: "pending",
  },
];

type Props = {
  currentHash: string;
  setPage: React.Dispatch<React.SetStateAction<string>>;
  setCurrHash: React.Dispatch<React.SetStateAction<string>>;
};

const Story = ({ currentHash, setPage, setCurrHash }: Props) => {
  const maxIndex = stories.map((item) => item.hash).indexOf(currentHash);
  console.log(maxIndex);
  return (
    <StoryWrapper>
      <div style={{ width: "80%" }}>
        <img src={FinboxLogo} style={{ marginTop: "30px" }} alt="FinBox" />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "30px",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <h2 style={{ color: "white" }}>Experience Embedded Finance SDK</h2>
          {maxIndex > 0 ? (
            <Button
              style={{ marginTop: "15px" }}
              onClick={() => {
                setPage("Start");
                setCurrHash("Start");
              }}
            >
              Restart
            </Button>
          ) : (
            <></>
          )}
        </div>
        <p style={{ color: "white", marginBottom: "0", paddingBottom: "50px" }}>
          Click through the emulator to experience customer perspective while
          going through the lending journey.{" "}
          <a
            href="https://finbox.in/contact-us"
            target="_blank"
            style={{ textDecoration: "none", color: "#24ca7a" }}
          >
            Reach out to us.
          </a>
        </p>
      </div>
      <StoryContainer id="story">
        <div style={{ height: "20vh" }}></div>
        {stories.map(
          (
            item: {
              head: string;
              hash: string;
              subhead: any;
              status: string;
            },
            index: number
          ) => (
            <div
              key={index}
              style={{ display: `${index <= maxIndex ? "flex" : "none"}` }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingRight: "10px",
                  alignItems: "center",
                }}
              >
                <Circle status={index <= maxIndex ? "done" : ""}>
                  {index < maxIndex ? (
                    <MdDone style={{ fontSize: "10px" }} />
                  ) : index === maxIndex ? (
                    <Dot />
                  ) : (
                    ""
                  )}
                </Circle>
                {item !== stories[stories.length - 1] ? (
                  <Line status={index < maxIndex ? "done" : ""} big={true} />
                ) : (
                  ""
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "left",
                }}
              >
                <Head id={item.hash} style={{ marginTop: "-2px" }}>
                  <b>{item.head}</b>
                </Head>
                <SubHead style={{ marginTop: "3px" }}>{item.subhead}</SubHead>
              </div>
            </div>
          )
        )}
      </StoryContainer>
      <div
        style={{
          display: "flex",
          width: "80%",
          justifyContent: "space-between",
          marginBottom: "10px",
          paddingTop: "10px",
        }}
      >
        <a
          href="https://finbox.in/contact-us"
          target="_blank"
          style={{ textDecoration: "none", color: "#24ca7a" }}
        >
          <u>Contact Us</u>
        </a>

        <a
          href="https://finbox.in/blog/what-is-embedded-finance-and-how-it-will-change-fintech-in-india/"
          target="_blank"
          style={{ textDecoration: "none", color: "#24ca7a" }}
        >
          <u>What is embedded finance?</u>
        </a>
      </div>
    </StoryWrapper>
  );
};
export default memo(Story);
