import React, {
  useContext,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react";
import Context from "../../../../contexts/AppContext";
import styled, { ThemeContext } from "styled-components";

import {
  Wrapper,
  Content,
  Head,
  SubText,
  LableAndInput,
  InputContainer,
  Button,
  Label,
} from "../../Common";
import { FaUser, FaLock } from "react-icons/fa";

const Row = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-between;
`;

const SecuriyGuide = ({ src, title }: { src: string; title: string }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <img src={src} />
      <SubText style={{ marginLeft: 8 }}>{title}</SubText>
    </div>
  );
};

const BankFooter = () => {
  return (
    <Row style={{ marginTop: 15 }}>
      <SecuriyGuide
        src="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/bankconnect_ic_shield.svg"
        title="Bank Grade Security"
      />
      <SecuriyGuide
        src="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/bankconnect_ic_lock.svg"
        title="256-Bit Encryption"
      />
    </Row>
  );
};

const ProcessingView = ({
  setScreen,
  changeStory,
}: {
  setScreen: Dispatch<SetStateAction<string>>;
  changeStory: (hash: string) => any;
}) => {
  const [stage, setStage] = useState("Initializing");

  useEffect(() => {
    setTimeout(() => {
      setStage("Connecting to your bank");
    }, 1000);
    setTimeout(() => {
      setStage("Verifying Credentials");
    }, 2000);
    setTimeout(() => {
      setStage("Credentials Verified");
    }, 3000);
    setTimeout(() => {
      setStage("Processing");
    }, 4000);
    setTimeout(() => {
      setScreen("DONE");
    }, 5000);
    setTimeout(() => {
      changeStory("LoanOffer");
    }, 6500);
  }, []);
  return (
    <div style={{ width: "80%", marginTop: "20px" }}>
      <div style={{ textAlign: "center" }}>
        {stage}
        <div style={{ display: "flex", marginTop: "10px" }}>
          <img
            src="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/bankconnect_ic_progress.gif"
            style={{ width: "100%" }}
          />
        </div>
      </div>
    </div>
  );
};

const BankLogin = ({ changeStory }: { changeStory: (hash: string) => any }) => {
  const context = Context();
  const theme = useContext(ThemeContext);
  const [screen, setScreen] = useState("LOGIN");

  return (
    <Wrapper>
      <Content>
        {screen === "LOGIN" ? (
          <>
            <Head>Enter Your Credentials</Head>
            <SubText>
              Enter your netbanking credentials to automatically fetch your bank
              statement
            </SubText>
          </>
        ) : (
          ""
        )}

        <img
          style={{ width: "50%", marginTop: "10px" }}
          src="https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/hdfc_light.png"
        />
        {screen === "PROCESSING" ? (
          <ProcessingView setScreen={setScreen} changeStory={changeStory} />
        ) : screen === "DONE" ? (
          <div style={{ marginTop: 60, textAlign: "center" }}>
            <img
              src="https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/bankconnect_check.svg"
              width={150}
            />
          </div>
        ) : (
          <>
            <InputContainer>
              <div style={{ width: "100%" }}>
                <LableAndInput
                  type="text"
                  key="customerid"
                  label="Customer ID"
                  value={"1234567"}
                  icon={<FaUser style={{ marginLeft: 8 }} />}
                />
              </div>
              <div style={{ marginTop: 10, width: "100%" }}>
                <LableAndInput
                  key="pwd"
                  type="password"
                  label="Password"
                  value={"youcantseeme"}
                  icon={<FaLock style={{ marginLeft: 8 }} />}
                />
              </div>
            </InputContainer>
            <Button
              style={{
                marginTop: "20px",
                borderRadius: "0",
                backgroundColor: "#24CA7A",
              }}
              onClick={() => setScreen("PROCESSING")}
            >
              Log In
            </Button>
            <SubText style={{ marginTop: "10px" }}>
              {" "}
              <u> Manually upload PDF statement </u>
            </SubText>
            <BankFooter />
          </>
        )}
      </Content>
    </Wrapper>
  );
};

export default BankLogin;
