import React, { useState, useCallback, useEffect } from "react";

import { ThemeProvider } from "styled-components";
import Themes from "./theme";
console.log(Themes);

export const FinBoxThemeProvider = (props: { children: React.ReactNode }) => {
  const [theme, setTheme] = useState({
    companyURL: "",
    primaryTextColor: "#3E3E3E",
    secondaryTextColor: "#24CA7A",
    secondaryTextBackground: "#d8f9ee",
    primaryButtonBackground: "#24CA7A",
    primaryButtonTextColor: "#ffffff",
    secondaryButtonBackground: "#ffffff",
    secondaryButtonTextColor: "#3E3E3E",
    secondaryButtonBorderColor: "#24CA7A",
    buttonDisabledBackground: "#d4d4d4",
    buttonDisabledTextColor: "#ffffff",
    cardBackground: "#F4F8FC",
    warningCardBackground: "#FFF2DC",
    tileBackground: "#F3F3F3",
    errorColor: "#ff6d55",
    focusColor: "#24CA7A",
    focusBackground: "#d8f9ee",
  });

  const setColor = useCallback(
    (key: string, color: string) => {
      let temp_theme: any = { ...theme };
      temp_theme[key] = color;
      setTheme(temp_theme);
    },
    [theme]
  );

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const company: string | null = urlParams.get("client");
    if (company)
      switch (company) {
        case "GOOGLE":
          setTheme(Themes.GOOGLE);
          break;
        case "SNAPBIZZ":
          setTheme(Themes.SNAPBIZZ);
          break;
        case "STOREKING":
          setTheme(Themes.STOREKING);
          break;
        case "ARZOOO":
          setTheme(Themes.ARZOOO);
          break;
        case "OXIGENWALLET":
          setTheme(Themes.OXIGEN);
          break;
        case "NIYO":
          setTheme(Themes.NIYO);
          break;
        case "ALIGNBOOKS":
          setTheme(Themes.ALIGNBOOKS);
          break;
        case "ABLEJOBS":
          setTheme(Themes.ABLEJOBS);
          break;
        case "INDIAMART":
          setTheme(Themes.INDIAMART)
          break;
        default:
          break;
      }
  }, []);

  return (
    <ThemeProvider theme={{ ...theme, setTheme, setColor }}>
      {props.children}
    </ThemeProvider>
  );
};

export default FinBoxThemeProvider;
