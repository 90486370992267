import React, { useEffect, useState, useRef,memo } from 'react';
import { Label, Input, InputErrorIcon, SmallText, WithLabel, ToolTip } from "./Common";
import { MdError } from "react-icons/md";


import styled from 'styled-components'

const InputBox = styled(Input)`
  width:40px;
  padding:0 15px;
  text-align:center;
`
const DobToolTip = styled(ToolTip)`
  bottom:-15px;
`

const DOB = (props) => {
  const [day, setDay] = useState("01");
  const [month, setMonth] = useState("12");
  const [year, setYear] = useState("1984");
  const [error, setError] = useState([]);
  const monthRef = useRef(null);
  const yearRef = useRef(null);

  useEffect(() => {
    let temp_error = []; // Error List
    if (parseInt(day) < 0 || parseInt(day) > 31) {
      temp_error.push("day")
    }
    if (parseInt(month) < 0 || parseInt(month) > 12) {
      temp_error.push("month")
    }
    if (year.length === 4 && (new Date().getFullYear() - parseInt(year) < 18 || new Date().getFullYear() - parseInt(year) >= 100)) {
      temp_error.push("year")
    }

    if (temp_error.length === 0 && year.length === 4 && month && day) {
      console.log(year + "-" + `${month.length === 1 ? "0" + month : month}` + "-" + `${day.length === 1 ? "0" + day : day}`)
      props.onChange(year + "-" + `${month.length === 1 ? "0" + month : month}` + "-" + `${day.length === 1 ? "0" + day : day}`)
    }
    else {
      props.onChange("")
    }

    setError(temp_error)
  }, [day, month, year])



  return (
    <WithLabel>
      <Label>{props.label}</Label>
      <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <div style={{ position: "relative" }}>
          <InputBox type="number" placeholder="DD" value={day}  />
          <InputErrorIcon>{error.includes("day") ? <MdError /> : ""}</InputErrorIcon>
          {error.includes("day") ? <DobToolTip>Invalid Day</DobToolTip> : ""}
        </div>
        <SmallText>/</SmallText>
        <div style={{ position: "relative" }}>
          <InputBox ref={monthRef} type="number" placeholder="MM" value={month}  />
          <InputErrorIcon>{error.includes("month") ? <MdError /> : ""}</InputErrorIcon>
          {error.includes("month") ? <DobToolTip>Invalid Month</DobToolTip> : ""}
        </div>
        <SmallText>/</SmallText>
        <div style={{ position: "relative" }}>
          <InputBox ref={yearRef} type="number" placeholder="YYYY" value={year}  style={{ width: "60px" }} />
          <InputErrorIcon>{error.includes("year") ? <MdError /> : ""}</InputErrorIcon>
          {error.includes("year") ? <DobToolTip>{new Date().getFullYear() - 100} - {new Date().getFullYear() - 18} </DobToolTip> : ""}
        </div>

      </div>
    </WithLabel>
  )
}

export default memo(DOB);