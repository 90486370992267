import React, { useEffect, useState, useRef, memo } from "react";
import styled from "styled-components";

import {
  Wrapper,
  Content,
  Head,
  Label,
  Input,
  InputContainer,
  Button,
  SubText,
  SmallText,
} from "../Common";

const InputBox = styled(Input)`
  padding: 0;
  margin: 5px;
  width: 35px;
  height: 35px;
  padding: 9px;
  box-sizing: border-box;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
`;

const OTPVerification = ({
  changeStory,
}: {
  changeStory: (hash: string) => any;
}) => {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);

  const [minutes, setMinutes] = useState(2);
  const [seconds, setSeconds] = useState(0);
  const [resend, setResend] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setOtp(["6", "4", "2", "0", "6", "3"]);
    }, 500);
    
    setTimeout(() => {
      changeStory("Experian")
    }, 1000);
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
          setResend(true);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  const ResendOTP = () => {
    setResend(false);
    setMinutes(2);
    setSeconds(0);
  };

  return (
    <Wrapper>
      <Content>
        <Head>Verification Code</Head>
        <SmallText style={{ alignSelf: "flex-start", marginTop: "5px" }}>
          OTP sent to: <b> 7034240550</b>
        </SmallText>
        <InputContainer>
          <Label>Enter the OTP here</Label>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <InputBox
              type="number"
              maxLength={1}
              value={otp[0] || ""}
            />
            <InputBox type="number" maxLength={1} value={otp[1] || ""} />
            <InputBox type="number" maxLength={1} value={otp[2] || ""} />
            <InputBox type="number" maxLength={1} value={otp[3] || ""} />
            <InputBox type="number" maxLength={1} value={otp[4] || ""} />
            <InputBox type="number" maxLength={1} value={otp[5] || ""} />
          </div>
        </InputContainer>
        <Button
          style={{ marginTop: "40px", marginBottom: "20px" }}
          onClick={() => changeStory("Experian")}
        >
          Verify
        </Button>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <SubText style={{ color: resend ? "#24CA7A" : "#d4d4d4" }}>
            Resend OTP
          </SubText>
          <SubText>
            {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
          </SubText>
        </div>
      </Content>
    </Wrapper>
  );
};

export default memo(OTPVerification);
