import React, { useContext, useState, useEffect, useRef } from "react";
import Context from "../../../contexts/AppContext";
import { ThemeContext } from "styled-components";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import {
  Wrapper,
  Content,
  Head,
  SubText,
  SmallText,
  Button,
  Label,
  StatusHead,
} from "../Common";
import { formatAmount } from "../../utils";

const CredLinePay = ({
  changeStory,
}: {
  changeStory: (hash: string) => any;
}) => {
  const context = Context();
  const theme = useContext(ThemeContext);
  const amount = 18000;
  const total =
    Number(context.eligibleAmount.replace(/,/g, "")) - 100 - (100 * 18) / 100;

  return (
    <Wrapper>
      <Content>
        <div style={{ width: "70%", marginTop: "0px" }}>
          <CircularProgressbarWithChildren
            value={(amount * 100) / total}
            styles={buildStyles({
              rotation: 0,

              // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
              strokeLinecap: "round",

              // Text size
              textSize: "14px",

              // How long animation takes to go from one percentage to another, in seconds
              pathTransitionDuration: 0.9,

              // Can specify path transition in more detail, or remove it entirely
              // pathTransition: 'none',

              // Colors
              pathColor: theme.secondaryTextColor,
              textColor: theme.secondaryTextColor,
              trailColor: "#d6d6d6",
              backgroundColor: "#3e98c7",
            })}
          >
            <div
              style={{
                marginTop: "-10px",
                textAlign: "center",
                fontSize: "11px",
              }}
            >
              <div>FinBox credit</div>
              <div
                style={{
                  color: "#24CA7A",
                  fontSize: "25px",
                  fontWeight: 900,
                  borderBottom: "1px dashed grey",
                  marginBottom: "5px",
                }}
              >
                ₹{formatAmount(amount)}
              </div>
              <b style={{ fontSize: "13px" }}>₹{formatAmount(total)}</b>
            </div>
          </CircularProgressbarWithChildren>
        </div>

        <div
          style={{
            border: "1px solid grey",
            width: "100%",
            borderRadius: "10px",
            display: "flex",
            boxSizing: "border-box",
            padding: "15px",
            justifyContent: "space-between",
            marginTop: "10px",
          }}
        >
          <div style={{ width: "60%" }}>
            <StatusHead>Pay in 30 days*</StatusHead>
            <SmallText style={{ fontStyle: "italic" }}>
              *Payment date subject
              <br /> to actual disbursal
            </SmallText>
          </div>
          <div>
            <StatusHead>
              <b> ₹12,500 </b>
            </StatusHead>
            <SmallText>
              0% interest
              <br />
              No Charges
            </SmallText>
          </div>
        </div>

        <SubText style={{ width: "90%", marginTop: "5px" }}>
          A late fee of 250/- will be charged on late payment.
        </SubText>

        <div style={{ margin: "50px 0", width: "100%" }}>
          <Button onClick={() => changeStory("CredLineRepay")}>Pay Now</Button>
        </div>
      </Content>
    </Wrapper>
  );
};

export default CredLinePay;
