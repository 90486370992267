import React, { useContext, useState, useEffect, useRef } from "react";
import Context from "../../../../contexts/AppContext";
import { ThemeContext } from "styled-components";

import { Wrapper, Content, Head, BankRow, List } from "../../Common";
import { MdAccountBalance } from "react-icons/md";

const BankList = ({ changeStory }: { changeStory: (hash: string) => any }) => {
  const context = Context();
  const theme = useContext(ThemeContext);
  const amount = 13000;
  const total =
    Number(context.eligibleAmount.replace(",", "")) - 100 - (100 * 18) / 100;

  return (
    <Wrapper>
      <Content>
        <Head>Connect Your Bank</Head>
        <List>
          <BankRow
            full_name="State Bank Of India"
            src="https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/sbi.png"
          />
          <BankRow
            full_name="HDFC Bank"
            onBankSelected={() => changeStory("BankLogin")}
            src="https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/hdfc.png"
            selected
          />
          <BankRow
            full_name="ICICI Bank"
            src="https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/icici.png"
          />
          <BankRow
            full_name="Axis Bank"
            src="https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/axis.png"
          />
          <BankRow
            full_name="Kotak Mahindra Bank"
            src="https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/kotak.png"
          />
          <BankRow
            full_name="IndusInd Bank"
            src="https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/indusind.png"
          />
          <BankRow
            full_name="Citi Bank"
            src="https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/citi.png"
          />
        </List>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            color: "#24CA7A",
            paddingLeft: "20px",
            marginTop: 25,
            cursor: "pointer",
            width: "95%",
          }}
        >
          <MdAccountBalance />
          <div style={{ marginLeft: "20px", fontSize: "13px" }}>
            Can't find my bank
          </div>
        </div>
      </Content>
    </Wrapper>
  );
};

export default BankList;
