import {
  Head,
  Wrapper,
  Content,
  Card,
  Button,
  SecHead,
  SmallText,
  StatusHead,
} from "../Common";
import { MdPerson, MdLocationOn } from "react-icons/md";
import DOB from "../DOB";
import sms from "../images/permissions/sms.svg";
import location from "../images/permissions/location.svg";
import device from "../images/permissions/device.svg";
import apps from "../images/permissions/apps.svg";

type CardProps = {
  image: string;
  head: string;
  sub: string;
};

const PermissionCard = ({ image, head, sub }: CardProps) => {
  return (
    <Card style={{ width: "45%", boxSizing: "border-box", padding: "10px",marginTop:"25px" }}>
      <div>
        <div style={{ display: "flex", alignItems: "center",marginBottom:"10px" }}>
          <img src={image} style={{ marginRight: "15px" }} />
          <StatusHead>{head}</StatusHead>
        </div>
        <SmallText>{sub}</SmallText>
      </div>
    </Card>
  );
};

const Permission = ({ changeStory }: { changeStory: (hash: string) => any }) => {
  return (
    <Wrapper>
      <Content>
        <Head>Permissions Required</Head>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
            marginTop:"20px"
          }}
        >
          <PermissionCard
            image={sms}
            head="SMS"
            sub="Collect and monitor your transactional messages to automatically fetch your expenses, bill..."
          />
          <PermissionCard
            image={device}
            head="Phone"
            sub="Collect and monitor specific information about your device to uniquely identify your device including...."
          />
          <PermissionCard
            image={location}
            head="Location"
            sub="Collect and monitor information about the location of your device to do risk assessment for..."
          />
          <PermissionCard
            image={apps}
            head="Apps"
            sub="Collect and monitor specific information about your device to uniquely identify your device including..."
          />
        </div>
        <div style={{ margin: "10px 0", width: "100%" ,position:"absolute",bottom:"15px"}}>
          <Button onClick={() => changeStory("BasicInfo")}>Grant Access</Button>
        </div>
      </Content>
    </Wrapper>
  );
};

export default Permission;
