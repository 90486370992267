export const ScrollStory = (ID: string) => {
  var element = document.getElementById(ID)!;
  var headerOffset = 150;
  var elementPosition = element.offsetTop;
  var offsetPosition = elementPosition - headerOffset;
  document.getElementById("story")!.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  });
};


export const formatAmount = (amount:number) => {
  return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}