import React, { useState, useEffect, memo } from "react";
import {
  Wrapper,
  Content,
  Head,
  Card,
  SmallText,
  SubText,
  Row,
  Button,
  SectionLabel,
  Chip,
  Tab,
} from "../Common";
import Context from "../../../contexts/AppContext";

const EmiCard = ({
  data,
  isOverdue,
  changeStory,
}: {
  data: any;
  isOverdue: boolean;
  changeStory: any;
}) => {
  return (
    <Card style={{ marginTop: "10px" }}>
      <div style={{ padding: "15px", width: "100%" }}>
        <Row>
          {isOverdue ? (
            <SmallText style={{ color: "#FF5364" }}> OVERDUE </SmallText>
          ) : (
            <SmallText> DUE ON </SmallText>
          )}
        </Row>
        <Row style={{ paddingTop: "5px" }}>
          <Card style={{ background: "#E5EAF3", width: "25%" }}>
            <div
              style={{
                width: "100%",
                padding: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>{data.dueDay}</div>
              <SubText>{data.dueMonth}</SubText>
            </div>
          </Card>
          <div>
            <SectionLabel>₹ {data.amount}</SectionLabel>
            <SubText style={{ marginTop: "5px" }}>
              {ordinal_suffix_of(data.installmentNum) + " Installment"}
            </SubText>
          </div>
          <div style={{ width: "20%" }}>
            {data.pay ? (
              <Button onClick={() => changeStory("Payment")}>Pay</Button>
            ) : (
              ""
            )}
          </div>
        </Row>
        <Row
          style={{ justifyContent: "center", marginTop: "5px", height: "15px" }}
        >
          {data.lateCharge ? (
            <Chip color="#FF5364" background="rgba(254, 103, 119, 0.12)">
              ₹{data.lateCharge} late payment
            </Chip>
          ) : (
            ""
          )}
        </Row>
      </div>
    </Card>
  );
};

const PaidEmiCard = ({ data }: { data: any }) => {
  return (
    <Row style={{ marginTop: "5px" }}>
      <SubText>{data.paidDate}</SubText>
      <SubText>₹ {data.amount + data.lateCharge}</SubText>
    </Row>
  );
};

const ordinal_suffix_of = (i: number) => {
  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + "st";
  }
  if (j == 2 && k != 12) {
    return i + "nd";
  }
  if (j == 3 && k != 13) {
    return i + "rd";
  }
  return i + "th";
};

const Action = ({ changeStory }: { changeStory: (hash: string) => any }) => {
  const [tab, setTab] = useState("unpaid");
  const context = Context();

  const changeTab = (t: string) => {
    if (t !== tab) setTab(t);
  };

  return (
    <Wrapper>
      <Content>
        <Head>Active Loans</Head>
        <Card style={{ marginTop: "10px" }}>
          <div style={{ padding: "15px", width: "100%" }}>
            <SmallText>Lender : Lender Name</SmallText>
            <div style={{ width: "100%", marginTop: "20px" }}>
              <Row style={{ marginTop: "5px" }}>
                <SubText>Loan Amount:</SubText>
                <SubText>
                  <b>₹ {context.eligibleAmount}</b>
                </SubText>
              </Row>
              <Row style={{ marginTop: "5px" }}>
                <SubText>EMI's Paid:</SubText>
                <SubText>
                  <b>2</b>
                </SubText>
              </Row>
              <Row style={{ marginTop: "5px" }}>
                <SubText>Next EMI Due:</SubText>
                <SubText>
                  <b>10-02-2021</b>
                </SubText>
              </Row>
            </div>
          </div>
        </Card>
        <Row style={{ justifyContent: "flex-start", marginTop: "15px" }}>
          <Tab selected={tab === "unpaid"} onClick={() => changeTab("unpaid")}>
            Upcoming
          </Tab>
          <Tab selected={tab === "paid"} onClick={() => changeTab("paid")}>
            Paid
          </Tab>
        </Row>
        {tab === "unpaid" ? (
          <>
            <EmiCard
              data={{
                amount: "2,600",
                lateCharge: "",
                pay: true,
                installmentNum: "3",
                dueDay: "10",
                dueMonth: "FEB",
              }}
              isOverdue={false}
              changeStory={changeStory}
            />
            <EmiCard
              data={{
                amount: "2,600",
                lateCharge: "",
                pay: false,
                installmentNum: "4",
                dueDay: "10",
                dueMonth: "MAR",
              }}
              isOverdue={false}
              changeStory={changeStory}
            />
          </>
        ) : (
          <Card style={{ marginTop: "10px" }}>
            <div style={{ padding: "15px", width: "100%" }}>
              <Row style={{ marginTop: "5px" }}>
                <SubText>
                  {" "}
                  <b> EMI Date</b>
                </SubText>
                <SubText>
                  <b> Amount</b>
                </SubText>
              </Row>

              <PaidEmiCard
                data={{ paidDate: "10-01-2021", amount: 2600, lateCharge: 0 }}
              />
              <PaidEmiCard
                data={{ paidDate: "10-12-2020", amount: 2600, lateCharge: 0 }}
              />
            </div>
          </Card>
        )}
      </Content>
    </Wrapper>
  );
};

export default memo(Action);
