import styled from "styled-components";
import { memo } from "react";

export const Wrapper = memo(styled.div`
  height: 100%;
  width: 100%;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
`);

export const Container = memo(styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  color: #211e32;
  font-weight: 400;
  flex-wrap: wrap;
  height: 100%;
  @media (max-width: 900px) {
    width: 100%;
    justify-content: center;
  }
`);
