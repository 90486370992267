import React, { useContext, useState } from "react";
import { ThemeContext } from "styled-components";
import Context from "../../../contexts/AppContext";

import {
  Head,
  Wrapper,
  Content,
  SubText,
  Button,
  Card,
  Chip,
  SmallText,
  StatusHead,
  Row,
  HL,
} from "../Common";
import { MdAccountBalance } from "react-icons/md";

// Dashboard also has a loading state

const Offer = ({
  amount,
  tenure,
  entity,
  onSubmit,
}: {
  amount: string;
  tenure: string;
  entity: string;
  onSubmit: () => any;
}) => {
  const theme = useContext(ThemeContext);
  const [selected, setSelected] = useState(false);
  return (
    <Card
      style={{
        marginBottom: "10px",
        cursor: "pointer",
        background: `${selected ? "#a8d4ff" : ""}`,
      }}
      className={`${selected ? "" : "onHover"}`}
      onClick={() => {
        setSelected(true);
        setTimeout(() => {
          onSubmit();
        }, 1000);
      }}
    >
      <div style={{ padding: "20px", width: "100%" }}>
        <Row style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div>
              <StatusHead
                style={{
                  color: theme.secondaryTextColor,
                  fontSize: "19px",
                }}
              >
                ₹ {amount}
              </StatusHead>
              <SmallText style={{ marginTop: 8 }}>
                Interest: <b>X% per month</b>
              </SmallText>
              {tenure ? (
                <SmallText style={{ marginTop: 4 }}>
                  Tenure: <b> {tenure} months</b>
                </SmallText>
              ) : (
                ""
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "32%",
              }}
            >
              <div
                style={{
                  width: 20,
                  height: 20,
                  padding: 8,
                  background: "#211e331a",
                  borderRadius: 4,
                  textAlign: "center",
                }}
              >
                <MdAccountBalance />
              </div>
              <SubText
                style={{
                  marginTop: "10px",
                  color: "black",
                  fontWeight: 500,
                }}
              >
                {entity}
              </SubText>
            </div>
          </div>
        </Row>
      </div>
    </Card>
  );
};

const LoanOffer = ({ changeStory }: { changeStory: (hash: string) => any }) => {
  const theme = useContext(ThemeContext);
  const context = Context();
  console.log(Context);
  return (
    <Wrapper>
      <Content>
        <Head>
          {context.loanType === "CREDITLINE"
            ? "Congratulations Ramesh"
            : "Loan Offers"}
        </Head>
        <SubText style={{ alignSelf: "flex-start", marginTop: "10px" }}>
          {context.loanType === "CREDITLINE"
            ? "You are eligible for a credit line. Choose your offer."
            : "View your offers"}
        </SubText>
        <div style={{ marginTop: "15px", width: "100%" }}>
          {context.offers.map((offer, index) => (
            <Offer
              amount={offer.eligibleAmount}
              tenure={context.loanType !== "CREDITLINE" ? offer.tenure : ""}
              onSubmit={() => {
                context.dispatch(index + 1);
                changeStory("DashboardKYC");
              }}
              entity={offer.lender}
              key={index}
            />
          ))}
        </div>

        {/* <div style={{ position: "absolute", bottom: "10px", width: "100%" }}>
          <Button>Accept</Button>
        </div> */}
      </Content>
    </Wrapper>
  );
};

export default LoanOffer;
