import React, { useState, useCallback,memo, useEffect, useRef, useContext } from "react"
import styled from 'styled-components';
import { Label } from './Common';
import { MdArrowDropDown } from "react-icons/md";
import { ThemeContext } from 'styled-components';


export const Select = styled.div`
    border:none;
    outline:none;
    width:100% ;
    height: 37px;
    background: ${(props) => props.theme.tileBackground};
    border-radius: 10px;
    font-size: 14px;
    letter-spacing: 1px;
    display:flex;
    justify-content:space-between;
    align-items:center;
    position:relative;
    cursor:pointer;
    @media (max-width :398px){
    font-size:12px;
  }
`
const Item = styled.div`
  padding:0 10px;
  color:#a4a4a4;
`
const Card = styled.div`
  position:absolute;
  top:0;
  left:0;
  width:100%;
  background:white;
  display: flex;
  flex-direction:column; 
  justify-content: space-between;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, .1);
  border-radius: 4px;
  align-items:flex-start;
  z-index:2;
`
const Option = styled.div`
  margin-top:0.5rem;
  margin-bottom:0.5rem;
  padding:0rem 2.3rem;
  color: ${(props) => props.theme.primaryTextColor};
  min-width:70%;
  cursor:pointer;
  &:hover{
    color: #969696;
  }
`


const DropDown = (props: { default: any; label: React.ReactNode; list: any[]; onChange: (arg0: any) => void; }) => {
  const [visible, setVisible] = useState(false);
  const theme = useContext(ThemeContext);
  const [selected, setSelected] = useState(props.default);
  const ref = useRef(null)
  
  const clickListener = useCallback(
    (e) => {
      if (ref.current && !(ref.current! as HTMLElement).contains(e.target)) {
        setVisible(false);
      }
    },
    [ref.current],
  )

  useEffect(() => {
    document.addEventListener('click', clickListener)
    return () => {
      document.removeEventListener('click', clickListener)
    }
  }, [])


  return (
    <div style={{ width: "100%", padding: ".5rem 0 ", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center" }}>
      <Label>{props.label}</Label>
      <Select onClick={() => !visible && setVisible(true)} ref={ref}>
        <div style={{color: theme.primaryTextColor,padding:"0 2.3rem"}}>{selected}</div>
        <Item><MdArrowDropDown style={{fontSize:"1.4rem"}}/></Item>
        {
          visible ? (
            <Card>
              {
                props.list.map((item: React.ReactNode, index: string | number | null | undefined) =>
                  <Option key={index} onClick={() => { setSelected(item); props.onChange(item); setVisible(false) }}>
                    {item}
                  </Option>
                )
              }
            </Card>
          )
            : ""
        }
      </Select>
    </div>
  )
}

export default memo(DropDown);