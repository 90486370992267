import React, { useState, useEffect, memo } from "react";

import Phone from "../components/Phone";
import PhoneBusiness from "../components/Phone/business";
import PhoneCreditLine from "../components/Phone/creditline";

import Story from "../components/Story";
import StoryBusiness from "../components/Story_Business";
import StoryCreditLine from "../components/Story_CreditLine";

import { Container } from "../components/Common";
import { FinBoxThemeProvider } from "../contexts/ThemeContext";
import { AppProvider } from "../contexts/AppContext";
import Context from "../contexts/AppContext";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
  Redirect,
} from "react-router-dom";

const Demo = () => {
  const [hash, setHash] = useState("Start");
  const [page, setPage] = useState("Start");

  useEffect(() => {
    console.log(page, hash);
  }, [page, hash]);

  return (
    <Container>
      <AppProvider>
        <FinBoxThemeProvider>
          <Router>
            <Switch>
              <Route exact path="/credit-line">
                <PhoneCreditLine
                  setCurrHash={setHash}
                  setPage={setPage}
                  page={page}
                />
                <StoryCreditLine
                  setCurrHash={setHash}
                  currentHash={hash}
                  setPage={setPage}
                />
              </Route>
              <Route exact path="/business">
                <PhoneBusiness
                  setCurrHash={setHash}
                  setPage={setPage}
                  page={page}
                />
                <StoryBusiness
                  setCurrHash={setHash}
                  currentHash={hash}
                  setPage={setPage}
                />
              </Route>
              <Route exact path="/">
                <Redirect to="/credit-line" />
                {/* <Phone setCurrHash={setHash} setPage={setPage} page={page} />
                <Story
                  setCurrHash={setHash}
                  currentHash={hash}
                  setPage={setPage}
                /> */}
              </Route>
            </Switch>
          </Router>
        </FinBoxThemeProvider>
      </AppProvider>
    </Container>
  );
};

export default memo(Demo);
