import {
  Wrapper,
  Content,
  Card,
  SecHead,
  StatusHead,
  SubText,
  SmallText,
} from "../Common";
import { useContext, memo, useEffect } from "react";
import { ThemeContext } from "styled-components";
import Loan from "../images/loan.svg";
import YourLogo from "../images/yourlogo.png";
import CreditLine from "../images/creditline.svg";

import Context from "../../../contexts/AppContext";

const Header: any = {
  NORMAL: "Get loan approved in 10 minutes.",
  BUSINESS: "Get Business loan upto ₹75,0000 ",
  CREDITLINE: "Apply for Credit Line",
};

const Start = ({ changeStory }: { changeStory: (hash: string) => any }) => {
  const theme = useContext(ThemeContext);
  const context = Context();

  useEffect(() => {
    if (context.loanType === "CREDITLINE") {
      if (window && window.hj) {
        window.hj("trigger", "creditline_start");
      }
    }
  }, [context.loanType]);

  return (
    <Wrapper>
      <Content>
        <div style={{ marginTop: "30%" }}>
          <img
            src={theme.companyURL ? theme.companyURL : YourLogo}
            alt="company"
            style={{ width: "65%", display: "block", margin: "0 auto" }}
          />
        </div>

        <div style={{ marginTop: "33%", width: "100%" }}>
          <Card
            style={{
              background: "#578CFF",
              color: "white",
              boxSizing: "border-box",
              padding: "15px",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
            onClick={() =>
              changeStory(
                context.loanType === "CREDITLINE" ? "BasicInfo" : "Permission"
              )
            }
          >
            <img
              src={context.loanType === "CREDITLINE" ? CreditLine : Loan}
              style={{ width: "25%" }}
              alt="Loan"
            />
            <div style={{ width: "70%" }}>
              <div style={{ fontSize: "13px", fontWeight: 600 }}>
                {Header[context.loanType]}
              </div>
              <div style={{ fontSize: "11px", marginTop: "5px" }}>
                100% digital process. Get instant approval
              </div>
            </div>
          </Card>
        </div>
      </Content>
    </Wrapper>
  );
};

export default memo(Start);
