import React, { useContext, memo } from "react";
import styled from "styled-components";
import { AiFillExclamationCircle } from "react-icons/ai";
import { MdDone } from "react-icons/md";
import { SubText, SmallText } from "./Common";
import { ThemeContext } from "styled-components";

export const Circle = styled.div<{ status: string }>`
  border-radius: 50%;
  height: 10px;
  width: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid
    ${(props) =>
      props.status === "done" || props.status === "next"
        ? "#24CA7A"
        : "#9e9e9e"};
  color: #24ca7a;
  font-size: 22px;
`;

export const Line = styled.div<{ status: string; big: boolean | undefined }>`
  margin-top: 1px;
  margin-bottom: 1px;
  width: 1px;
  border-right: 1px
    ${(props) => (props.status === "done" ? "solid #24CA7A" : "dashed #9e9e9e")};
  height: ${(props) => (props.big ? "80px" : "45px")};
  /* background: #24CA7A; */
`;
export const Dot = styled.div`
  border-radius: 50%;
  height: 6px;
  width: 6px;
  background: #24ca7a;
`;
export const Err = styled.div`
  color: ${(props) => props.theme.errorColor};
`;

const Timeline = (props: {
  style: React.CSSProperties | undefined;
  timeline: any[];
}) => {
  const theme = useContext(ThemeContext);

  return (
    <div style={props.style}>
      {props.timeline.map(
        (
          item: {
            status: string;
            head: React.ReactNode;
            sub: {} | null | undefined;
            big?: boolean | undefined;
          },
          index: string | number | null | undefined
        ) => (
          <div key={index} style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingRight: "10px",
                alignItems: "center",
              }}
            >
              <Circle status={item.status}>
                {item.status === "done" ? (
                  <MdDone />
                ) : item.status === "failed" ? (
                  <AiFillExclamationCircle
                    style={{ color: theme.errorColor }}
                  />
                ) : item.status === "next" ? (
                  <Dot />
                ) : (
                  ""
                )}
              </Circle>
              {item !== props.timeline[props.timeline.length - 1] ? (
                <Line status={item.status} big={item.big} />
              ) : (
                ""
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
              }}
            >
              <SubText style={{ marginTop: "-2px" }}>
                <b>{item.head}</b>
              </SubText>
              <SmallText style={{ marginTop: "3px" }}>
                {item.status === "failed" ? <Err> {item.sub} </Err> : item.sub}
              </SmallText>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default memo(Timeline);
