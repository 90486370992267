import {
  Head,
  Wrapper,
  Content,
  InputWithLabel,
  InputContainer,
  Button,
  ChipRadio,
  SubText,
} from "../Common";
import { MdPerson, MdLocationOn } from "react-icons/md";
import DOB from "../DOB";
import { FaAddressCard } from "react-icons/fa";

import Context from "../../../contexts/AppContext";

const genderList = ["Female", "Male", "Other"];

const BasicInfo = ({ changeStory }: { changeStory: (hash: string) => any }) => {
  const context = Context();
  return (
    <Wrapper>
      <Content>
        <Head>
          {["NORMAL", "CREDITLINE"].includes(context.loanType)
            ? "Complete your profile"
            : "Director Details"}
        </Head>
        <InputContainer>
          <InputWithLabel
            label="Full Name"
            placeholder="Enter your full name"
            errorText="Invalid Name"
            value={"Ramesh"}
            maxLength={30}
            onChange={() => {}}
            leftIcon={<MdPerson />}
            error={false}
          />
          <InputWithLabel
            label="Email"
            value={"Ramesh@finbox.in"}
            maxLength={50}
            placeholder="Enter your email id"
            errorText="Invalid Email id"
            onChange={() => {}}
            type="email"
            leftIcon="@"
            error={false}
          />
          {context.loanType === "CREDITLINE" ? (
            <>
              <InputWithLabel
                placeholder={"Proprietor's Pan details"}
                label="PAN"
                value={"AAAPZ1234C"}
                onChange={() => {}}
                leftIcon={<FaAddressCard />}
              />
              <div style={{ display: "flex", marginTop: "35px" }}>
                <div style={{ paddingRight: "5px" }}>
                  <input type="checkbox" checked={true} />
                </div>
                <SubText>
                  I here by consent to FinBox being appointed as my authorised
                  representative to receive my Credit Information from Bureau
                  for the use of credit assessment and facilitation of
                  credit/loan offers best suited to me
                </SubText>
              </div>
            </>
          ) : (
            <>
              <ChipRadio
                label="Gender"
                list={genderList}
                default={"Male"}
                onChange={() => {}}
                error={false}
              />

              <DOB label="Date Of Birth" onChange={() => {}} />
              <InputWithLabel
                value={"680543"}
                onChange={() => {}}
                label="Pin Code"
                maxLength={6}
                placeholder="Enter your Pin Code"
                errorText="Invalid Pin Code"
                type="number"
                error={false}
                leftIcon={<MdLocationOn />}
              />
            </>
          )}
        </InputContainer>
        <div style={{ margin: "15px 0", width: "100%" }}>
          <Button
            onClick={() =>
              changeStory(
                context.loanType === "CREDITLINE"
                  ? "Experian"
                  : context.loanType === "NORMAL"
                  ? "Pan"
                  : "CompanyDetails"
              )
            }
          >
            Next
          </Button>
        </div>
      </Content>
    </Wrapper>
  );
};

export default BasicInfo;
