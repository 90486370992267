import React, {
  useReducer,
  createContext,
  useMemo,
  useState,
  useCallback,
} from "react";

type State = {
  lender: string;
  eligibleAmount: string;
  tenure: string;
  dispatch: React.Dispatch<any>;
  setLoanType: React.Dispatch<React.SetStateAction<string>>;
  loanType: string;
  offers: {
    eligibleAmount: string;
    tenure: string;
    lender: string;
  }[];
};
const AppContext = createContext<State | undefined>(undefined);

const Context = () => {
  const context = React.useContext(AppContext);
  if (context === undefined) throw new Error("Context not defined");

  return context;
};

const normalOffers = [
  {
    eligibleAmount: "50,000",
    tenure: "6",
    lender: "Bank1",
  },
  {
    eligibleAmount: "30,000",
    tenure: "4",
    lender: "NBFC1",
  },
  {
    eligibleAmount: "15,000",
    tenure: "4",
    lender: "Small Bank 1",
  },
];

const businessOffers = [
  {
    eligibleAmount: "1,00,000",
    tenure: "8",
    lender: "Bank1",
  },
  {
    eligibleAmount: "75,000",
    tenure: "6",
    lender: "NBFC1",
  },
  {
    eligibleAmount: "50,000",
    tenure: "4",
    lender: "Small Bank 1",
  },
];

export const AppProvider = (props: any) => {
  const [loanType, setLoanType] = useState("NORMAL");
  const offers = useMemo(() => {
    return loanType === "NORMAL" ? normalOffers : businessOffers;
  }, [loanType]);

  const reducer = useCallback(
    (state: any, action: any) => {
      switch (action) {
        case 1:
          return offers[0];
        case 2:
          return offers[1];
        case 3:
          return offers[2];
        default:
          return {
            lender: "Small Bank 2",
            eligibleAmount: "15,000",
            tenure: "4",
          };
      }
    },
    [loanType]
  );

  const [state, dispatch] = useReducer(reducer, {
    lender: "Lender",
    eligibleAmount: "15,000",
    tenure: "4",
  });

  return (
    <AppContext.Provider
      value={{ ...state, dispatch, loanType, setLoanType, offers }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default Context;
