import React, {
  useState,
  useCallback,
  memo,
  useEffect,
  useRef,
  useContext,
} from "react";
import {
  SmallText,
  SubText,
  Row,
  HL,
  Input,
  Button,
  InputContainer,
  Head,
  Label,
} from "./Common";
import { MdArrowDropDown } from "react-icons/md";
import styled, { ThemeContext } from "styled-components";
import AppContext from "../../contexts/AppContext";

const InputBox = styled(Input)`
  padding: 0;
  margin: 5px;
  width: 30px;
  height: 30px;
  padding: 9px;
  box-sizing: border-box;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  background: white;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
`;

const OTPPopOver = ({
  changeStory,
  to,
}: {
  changeStory: (hash: string) => any;
  to: string;
}) => {
  const [visible, setVisible] = useState(false);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);

  const [minutes, setMinutes] = useState(2);
  const [seconds, setSeconds] = useState(0);

  const theme = useContext(ThemeContext);

  const enterOTP = () => {
    setOtp(["", "", "", "", "", ""]);
    setTimeout(() => {
      setOtp(["6", "4", "2", "0", "6", "3"]);
    }, 1000);

    setTimeout(() => {
      changeStory(to)
      console.log(to)
    }, 2000);
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  };

  useEffect(() => {});

  const inEffect = `
  @keyframes react-fade-in {
    0%   { opacity: .5; bottom:-50px}
    25%  { opacity: .7; bottom:-20px}
    50%  { opacity: .8; bottom:-10px}
    75%  { opacity: .9; bottom:0px}
    100% { opacity: 1; bottom:5px}
  }
`;

  return (
    <>
      <style children={inEffect} />
      <div>
        <Button
          onClick={() => {
            setVisible(true);
            enterOTP();
          }}
        >
          Pay Now
        </Button>
      </div>
      {visible ? (
        <div
          onClick={() => setVisible(false)}
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            bottom: "0px",
            left: "0px",
          }}
        >
          <div
            style={{
              width: "115%",
              position: "absolute",
              bottom: "-38px",
              left: "-20px",
              background: "#f1f1f1fe",
              animationDuration: ".2s",
              animationDelay: "0s",
              animationIterationCount: 1,
              animationName: "react-fade-in",
              animationTimingFunction: "ease-in",
            }}
          >
            <div style={{ padding: "15px" }}>
              <Head>Verification Code</Head>
              <SmallText style={{ alignSelf: "flex-start", marginTop: "5px" }}>
                OTP sent to: <b> 7034240550</b>
              </SmallText>
              <InputContainer>
                <Label>Enter the OTP here</Label>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <InputBox type="number" maxLength={1} value={otp[0] || ""} />
                  <InputBox type="number" maxLength={1} value={otp[1] || ""} />
                  <InputBox type="number" maxLength={1} value={otp[2] || ""} />
                  <InputBox type="number" maxLength={1} value={otp[3] || ""} />
                  <InputBox type="number" maxLength={1} value={otp[4] || ""} />
                  <InputBox type="number" maxLength={1} value={otp[5] || ""} />
                </div>
              </InputContainer>
              <Button style={{ marginTop: "20px", marginBottom: "20px" }}>
                Verify
              </Button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default OTPPopOver;
