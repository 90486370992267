import React, { useContext, useState, useEffect } from "react";

import {
  Head,
  Wrapper,
  Content,
  TopBar,
  SubText,
  InputWithLabel,
  InputContainer,
  Button,
  Card,
  TileWithLabel,
  Label,
} from "../Common";
import { MdAccountBalance, MdAccountCircle, MdCheck } from "react-icons/md";
import Context from "../../../contexts/AppContext";

const PennyDrop = ({ changeStory }: { changeStory: (hash: string) => any }) => {
  const [accNum, setAccNum] = useState("52523553");
  const [reAccNum, setReAccNum] = useState("52523553");
  const [ifsc, setIfsc] = useState("SBIN0008483");
  const [ifscVerified, setIfscVerified] = useState(false);
  const [bankDetails, setBankDetails] = useState(null);
  const [selected, setSelected] = useState(false);
  const context = Context();
  useEffect(() => {
    setTimeout(() => {
      setSelected(true);
    }, 1200);
  }, []);
  return (
    <Wrapper>
      <Content>
        <TopBar></TopBar>
        <Head>
          {" "}
          {context.loanType === "NORMAL"
            ? "Bank Account Details"
            : "Setup ENACH"}
        </Head>
        <SubText style={{ marginTop: "10px" }}>
          The account details you provide here, must be yours and the account
          holder's name has to be same as your PAN Card name.
        </SubText>
        <InputContainer>
          <InputWithLabel
            label="Account Number"
            value={accNum}
            placeholder="Enter your account number"
            onChange={() => {}}
            leftIcon={<MdAccountBalance />}
          />
          <InputWithLabel
            label="Re-enter Account Number"
            value={reAccNum}
            placeholder="Enter your account number"
            onChange={() => {}}
            leftIcon={<MdAccountBalance />}
          />
          <InputWithLabel
            label="IFSC Code"
            value={ifsc}
            placeholder="Enter your IFSC code"
            maxLength={11}
            onChange={() => {}}
            leftIcon={<MdAccountCircle />}
            error={false}
            tick={ifscVerified}
          />
          <Label>Setup Enach</Label>
          <TileWithLabel
            label=""
            header="Setup with Netbanking"
            onClick={() => {}}
            leftIcon={<MdCheck style={{ color: "#dedede" }} />}
          />
          <TileWithLabel
            label=""
            header="Setup with Debit Card"
            onClick={() => {}}
            leftIcon={<MdCheck style={{ color: selected ? "#24ca7a" : "" }} />}
          />
        </InputContainer>
        <div style={{ margin: "10px 0", width: "100%" }}>
          <Button
            style={{ marginTop: "10px", marginBottom: "10px" }}
            onClick={() => changeStory("DashboardSign")}
            disabled={!selected}
          >
            Verify
          </Button>
        </div>
      </Content>
    </Wrapper>
  );
};

export default PennyDrop;
