import React, { useContext, useState, useEffect, useRef } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import agreementHtml from "./sampleAgreement";

import Context from "../../../contexts/AppContext";

import { Wrapper, Content, Head, SubText, SmallText, Button } from "../Common";

const SignAgreement = ({
  changeStory,
}: {
  changeStory: (hash: string) => any;
}) => {
  const context = Context();
  const [isAgree, setIsAgree] = useState(true);
  const [showDown, setShowDown] = useState(false);
  const endRef = useRef<HTMLDivElement>(document.createElement("div"));
  const scrollToBottom = () => {
    setShowDown(false);
    endRef!.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const onScroll = () => {
      console.log("Hello");
      document.removeEventListener("scroll", onScroll);
    };

    document.addEventListener("scroll", onScroll);
    return () => {
      document.removeEventListener("scroll", onScroll);
    };
  }, []);

  const buttonDisabled = !isAgree;
  return (
    <Wrapper>
      <Content>
        <Head>Sign Loan Agreement</Head>
        <SubText style={{ marginTop: "10px" }}>
          Sign with OTP on your registered Mobile Number and Face verification
        </SubText>

        {/* <div
          dangerouslySetInnerHTML={{ __html: agreementHtml }}
          // src="/sampleAgreement.html"
          style={{ marginTop: "15px" }}
        /> */}

        <div style={{ height: "max-content", width: "100%" }}>
          <div style={{ display: "flex", marginTop: "15px" }}>
            <input
              type="checkbox"
              checked={isAgree}
              style={{ height: "max-content" }}
            />
            <SmallText>
              This Loan Agreement (the “Agreement”) is made on 11th Day of
              November 2020 (“Effective Date”) between: Borrower Name, resident
              of Borrower address (hereinafter referred to as “Borrower” which
              expression shall unless excluded by or repugnant to the subject or
              context be deemed to include its legal heirs, administrators and
              permitted assigns) of the ONE PART AND NBFC, a company
              incorporated under the provisions of the Companies Act, 2013 and
              having its registered office at NBFC Address (hereinafter referred
              to as “Lender” which expression unless repugnant to the context or
              meaning thereof be deemed to include its successors and permitted
              assigns) of the OTHER PART.
            </SmallText>
          </div>
          <Button
            style={{ position: "absolute", bottom: "10px" }}
            onClick={() =>
              changeStory(
                ["NORMAL", "BUSINESS"].includes(context.loanType)
                  ? "LoanApprovalInfo"
                  : "CreditLineApproved"
              )
            }
          >
            I Agree
          </Button>
          <div ref={endRef} />
        </div>
        {showDown ? (
          <div
            style={{
              position: "fixed",
              bottom: "40px",
              width: "100%",
              textAlign: "center",
            }}
          >
            <MdKeyboardArrowDown
              style={{ fontSize: "4.5rem", color: "#24CA7A", fontWeight: 700 }}
              onClick={scrollToBottom}
            />
          </div>
        ) : (
          ""
        )}
      </Content>
    </Wrapper>
  );
};

export default SignAgreement;
