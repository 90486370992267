import React, { useContext, useState, useEffect, useRef } from "react";
import Context from "../../../../contexts/AppContext";
import { ThemeContext } from "styled-components";

import {
  Wrapper,
  Content,
  Head,
  SubText,
  SmallText,
  Button,
  Label,
  StatusHead,
} from "../../Common";
import { formatAmount } from "../../../utils";
import { MdNavigateNext } from "react-icons/md";
import { BsCreditCard } from "react-icons/bs";
import VisaMaster from "../../images/visamaster.png";
import Finbox from "../../images/finboxDark.svg";

const Payment = ({ changeStory }: { changeStory: (hash: string) => any }) => {
  const context = Context();
  const theme = useContext(ThemeContext);
  const amount = 18000;
  const total =
    Number(context.eligibleAmount.replace(/,/g, "")) - 100 - (100 * 18) / 100;

  useEffect(() => {
    if (context.loanType === "CREDITLINE") {
      if (window && window.hj) {
        window.hj("trigger", "creditline_end");
      }
    }
  }, [context.loanType]);

  return (
    <Wrapper>
      <Content>
        <Head
          style={{
            marginBottom: "30px",
          }}
        >
          Choose Payment Option
        </Head>

        <div
          style={{
            border: "1px solid #d2d2d2",
            width: "100%",
            display: "flex",
            boxSizing: "border-box",
            padding: "10px",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <div
            style={{
              height: "max-content",
              display: "flex",
              alignItems: "center",
            }}
          >
            <SubText style={{ width: "75px" }}>UPI</SubText>

            <img
              style={{
                height: "10px",
                marginLeft: "10px",
                marginBottom: "-3px",
              }}
              src="http://logo.clearbit.com/npci.org.in/"
            />
          </div>
          <MdNavigateNext style={{ color: "#d2d2d2" }} />
        </div>

        <div
          style={{
            border: "1px solid #d2d2d2",
            width: "100%",
            display: "flex",
            boxSizing: "border-box",
            padding: "10px",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <div
            style={{
              height: "max-content",
              display: "flex",
              alignItems: "center",
            }}
          >
            <SubText style={{ width: "75px" }}>Credit Card</SubText>
            <img
              style={{
                height: "10px",
                marginLeft: "10px",
                marginBottom: "-3px",
              }}
              src={VisaMaster}
            />
          </div>
          <MdNavigateNext style={{ color: "#d2d2d2" }} />
        </div>

        <div
          style={{
            border: "1px solid #d2d2d2",
            width: "100%",
            display: "flex",
            boxSizing: "border-box",
            padding: "10px",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <div
            style={{
              height: "max-content",
              display: "flex",
              alignItems: "center",
            }}
          >
            <SubText style={{ width: "75px" }}>Debit Card</SubText>
            <img
              style={{
                height: "10px",
                marginLeft: "10px",
                marginBottom: "-3px",
              }}
              src={VisaMaster}
            />
          </div>
          <MdNavigateNext style={{ color: "#d2d2d2" }} />
        </div>

        <div
          style={{
            border: "1px solid #c38cff",
            width: "100%",
            display: "flex",
            boxSizing: "border-box",
            padding: "10px",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <div
            style={{
              height: "max-content",
              display: "flex",
              alignItems: "center",
            }}
          >
            <SubText style={{ width: "75px", color: "#8700ff" }}>
              Credit Line
            </SubText>
            <div style={{ marginLeft: "5px" }}>
              <SmallText style={{ fontSize: "8px", marginBottom: "-3px" }}>
                Powered by
              </SmallText>
              <img
                style={{
                  height: "13px",
                }}
                src={Finbox}
              />
            </div>
          </div>
          <MdNavigateNext style={{ color: "#c38cff" }} />
        </div>
        <div style={{ position: "absolute", bottom: "10px", width: "100%" }}>
          <Button onClick={() => changeStory("CreditLineSelectMode")}>
            Complete Order
          </Button>
        </div>
      </Content>
    </Wrapper>
  );
};

export default Payment;
