import React, { useEffect, memo } from "react";
import { Wrapper, Content, Head, SubText } from "../Common";
import Lottie from "react-lottie";
import Loader from "../images/lottie/Loader.json";
import ExperianIcon from "../images/experian_icon.png";
import loader from "../images/loading.gif";
import Context from "../../../contexts/AppContext";

// Dashboard also has a loading state

const Experian = ({ changeStory }: { changeStory: (hash: string) => any }) => {
  const context = Context();

  useEffect(() => {
    setTimeout(() => {
      changeStory(
        ["NORMAL", "CREDITLINE"].includes(context.loanType)
          ? "LoanOffer"
          : "BankList"
      );
    }, 1000);
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Wrapper>
      <Content>
        <Head>Fetching Credit Report</Head>
        <SubText style={{ marginTop: "10px" }}>
          Please don't press back button or close this screen.
          <br />
          Getting credit report 
          {/* from{" "} */}
          {/* <img
            style={{ height: "20px", marginBottom: "-6px" }}
            src={ExperianIcon}
            alt="experian"
          /> */}
          .
        </SubText>
        <div style={{ position: "absolute", top: "47%" }}>
          <Lottie options={defaultOptions} width="100%" />
        </div>
      </Content>
    </Wrapper>
  );
};

export default memo(Experian);
