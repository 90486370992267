import React from "react";
import Demo from "./screens/Demo";
import { Wrapper } from "./components/Common";
// import { FinBoxThemeProvider } from "./contexts/ThemeContext";

function App() {
  return (
    // <FinBoxThemeProvider>
      <Wrapper>
        <Demo />
      </Wrapper>
    // </FinBoxThemeProvider>
  );
}

export default App;
