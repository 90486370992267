import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "styled-components";
import Context from "../../../../contexts/AppContext";
import macbook from "../../images/productlist/macbook.jpeg";
import fridge from "../../images/productlist/fridge.jpeg";

import styled from "styled-components";
import {
  Head,
  Wrapper,
  Content,
  SubText,
  Card,
  Chip,
  SmallText,
  StatusHead,
  Row,
  HL,
  Button,
} from "../../Common";

// Dashboard also has a loading state

const CheckOut = ({ changeStory }: { changeStory: (hash: string) => any }) => {
  const theme = useContext(ThemeContext);
  const context = Context();

  const Key = styled(SubText)`
    width: 30%;
  `;
  const Value = styled(SubText)`
    padding-left: 5px;
  `;

  return (
    <Wrapper>
      <Content>
        <Head>Checkout Page</Head>
        <br />
        {/* <Card>
          <Row style={{ boxSizing: "border-box", padding: "10px" }}>
            <img src={macbook} style={{ height: "50px" }} />
            <SubText>
              Macbook air
              <br />₹ 20,000
            </SubText>
            <SubText>
              - <b>1</b> +
            </SubText>
          </Row>
        </Card>
        <br />
        <Card>
          <Row style={{ boxSizing: "border-box", padding: "10px" }}>
            <img src={macbook} style={{ height: "50px" }} />
            <SubText>
              Macbook pro
              <br />₹ 30,000
            </SubText>
            <SubText>
              - <b>1</b> +
            </SubText>
          </Row>
        </Card>
        <br />
        <Card>
          <Row style={{ boxSizing: "border-box", padding: "10px" }}>
            <img src={fridge} style={{ height: "50px" }} />
            <SubText>
              LG ST4 Fridge
              <br />₹ 5,000
            </SubText>
            <SubText>
              - <b>1</b> +
            </SubText>
          </Row>
        </Card> */}

        <Card>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              padding: "20px",
            }}
          >
            <Row>
              <Key style={{ fontWeight: 500 }}>Item 1 x 1:</Key>{" "}
              <Value style={{ fontSize: "13px" }}>₹ 20,000</Value>{" "}
            </Row>
            <Row style={{ marginTop: "10px" }}>
              <Key style={{ fontWeight: 500 }}>Item 2 x 5:</Key>{" "}
              <Value style={{ fontSize: "13px" }}>₹ 15,000</Value>{" "}
            </Row>
            <Row style={{ marginTop: "10px" }}>
              <Key style={{ fontWeight: 500 }}>Item 3 x 6:</Key>{" "}
              <Value style={{ fontSize: "13px" }}>₹ 20,000</Value>{" "}
            </Row>
            <HL style={{ marginTop: "10px", borderTop: "1px solid #e9e9e9" }} />
            <Row style={{ marginTop: "5px" }}>
              <Key>Packaging</Key>{" "}
              <Value style={{ fontSize: "11px" }}>₹ 20</Value>{" "}
            </Row>
            <Row style={{ marginTop: "5px" }}>
              <Key>GST</Key> <Value style={{ fontSize: "11px" }}>18%</Value>{" "}
            </Row>
            <HL style={{ marginTop: "10px",borderTop: "1px solid #acacac" }} />
            <Row style={{ marginTop: "10px" }}>
              <Key>
                {" "}
                <b>Order Total</b>
              </Key>
              <Value>
                <b>₹ 55,000</b>
              </Value>
            </Row>
          </div>
        </Card>

        <div style={{ position: "absolute", bottom: "10px", width: "100%" }}>
          <Button onClick={() => changeStory("CreditLinePayment")}>
            Pay Now
          </Button>
        </div>
      </Content>
    </Wrapper>
  );
};

export default CheckOut;
