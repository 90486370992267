import React, { useContext, useState, useEffect, useRef } from "react";
import Context from "../../../contexts/AppContext";
import { ThemeContext } from "styled-components";

import {
  Wrapper,
  Content,
  Head,
  SubText,
  SmallText,
  Button,
  Label,
  StatusHead,
} from "../Common";
import { formatAmount } from "../../utils";

const CredLineRepayPay = ({
  changeStory,
}: {
  changeStory: (hash: string) => any;
}) => {
  const context = Context();
  const theme = useContext(ThemeContext);
  const amount = 18000;
  const total =
    Number(context.eligibleAmount.replace(/,/g, "")) - 100 - (100 * 18) / 100;

  return (
    <Wrapper>
      <Content>
        <Head
          style={{
            marginBottom: "30px",
          }}
        >
          RePay Credits
        </Head>

        <div
          style={{
            border: "1px solid grey",
            width: "100%",
            borderRadius: "10px",
            display: "flex",
            boxSizing: "border-box",
            padding: "15px",
            justifyContent: "space-between",
            marginTop: "10px",
          }}
        >
          <div style={{ width: "60%" }}>
            <StatusHead>
              <b> ₹12,500 </b>
            </StatusHead>
            <SmallText>Due Date</SmallText>
          </div>
          <div>
            <Button onClick={() => changeStory("Payment")}>Pay</Button>
          </div>
        </div>

        <div
          style={{
            border: "1px solid grey",
            width: "100%",
            borderRadius: "10px",
            display: "flex",
            boxSizing: "border-box",
            padding: "15px",
            justifyContent: "space-between",
            marginTop: "10px",
          }}
        >
          <div style={{ width: "60%" }}>
            <StatusHead>
              <b> ₹35,500 </b>
            </StatusHead>
            <SmallText>Due Date</SmallText>
          </div>
          <div>
            <Button disabled={true}>Paid</Button>
          </div>
        </div>

        <SubText style={{ width: "90%", marginTop: "5px" }}>
          A late fee of 250/- will be charged on late payment.
        </SubText>
      </Content>
    </Wrapper>
  );
};

export default CredLineRepayPay;
