import React, { useState, useEffect, useContext, memo, useMemo } from "react";
import {
  Wrapper,
  Content,
  Button,
  Head,
  TileWithLabel,
  InputContainer,
  SectionLabel,
  SmallText,
} from "../Common";
import { ThemeContext } from "styled-components";
import Context from "../../../contexts/AppContext";

import { MdNavigateNext } from "react-icons/md";
import { MdDone } from "react-icons/md";

const KYC = ({ changeStory }: { changeStory: (hash: string) => any }) => {
  const [done, setDone] = useState<Array<string>>([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const theme = useContext(ThemeContext);
  const context = Context();

  useEffect(() => {
    setTimeout(() => {
      setDone(["Photo"]);
    }, 500);
    setTimeout(() => {
      setDone(["Photo", "Pan_Card"]);
    }, 1000);
    setTimeout(() => {
      setDone(["Photo", "Pan_Card", "Address_Proof", "GST"]);
    }, 1500);
    setTimeout(() => {
      setIsDisabled(false);
    }, 2000);
  }, []);

  const getIcon = (type: string) => {
    if (done.includes(type)) return <MdDone style={{ color: "#24CA7A" }} />;
    return <></>;
  };

  const RightIcon = useMemo(() => <MdNavigateNext />, []);
  const ekycLeftIcon = useMemo(() => getIcon("eKyc"), [done]);
  const addrLeftIcon = useMemo(() => getIcon("Address_Proof"), [done]);
  const gstLeftIcon = useMemo(() => getIcon("GST"), [done]);

  return (
    <Wrapper>
      <Content>
        <Head>KYC Documents</Head>
        <InputContainer>
          <TileWithLabel
            label="Your Photo"
            header="Take your selfie"
            onClick={() => {}}
            rightIcon={RightIcon}
            leftIcon={useMemo(() => getIcon("Photo"), [done])}
          />
          <TileWithLabel
            label="PAN Card"
            header="Upload your PAN card"
            onClick={() => {}}
            rightIcon={RightIcon}
            leftIcon={useMemo(() => getIcon("Pan_Card"), [done])}
          />
          {context.loanType === "NORMAL" ? (
            ""
          ) : (
            <TileWithLabel
              label="GST Certificate"
              header="Upload GST certificate"
              onClick={() => {}}
              rightIcon={RightIcon}
              leftIcon={gstLeftIcon}
            />
          )}

          <SectionLabel>Address Proof</SectionLabel>
          {!done.includes("Address_Proof") ? (
            <TileWithLabel
              label="e-Aadhaar"
              header="Upload Address Using e-Aadhaar"
              onClick={() => {}}
              rightIcon={RightIcon}
              leftIcon={ekycLeftIcon}
            />
          ) : (
            ""
          )}
          {!done.includes("Address_Proof") && !done.includes("eKyc") ? (
            <SmallText
              style={{ width: "100%", textAlign: "center", marginTop: "10px" }}
            >
              or
            </SmallText>
          ) : (
            ""
          )}
          {!done.includes("eKyc") ? (
            <TileWithLabel
              label="Manual Upload"
              header="Choose any Govt. ID to upload"
              onClick={() => {}}
              rightIcon={RightIcon}
              leftIcon={addrLeftIcon}
            />
          ) : (
            ""
          )}
        </InputContainer>

        <div style={{ margin: "10px 0", width: "100%" }}>
          <Button
            disabled={isDisabled}
            onClick={() => changeStory("DashboardPennyDrop")}
          >
            Submit
          </Button>
        </div>
      </Content>
    </Wrapper>
  );
};

export default memo(KYC);
